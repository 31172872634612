.modal-content {
  composes: pa3 tc from '../../style/utilities';
}
.modal-title {
  composes: f4 tc from '../../style/utilities';
}
.confirm-button,
.cancel-button {
  composes: mh2 mt3 fw7 from '../../style/utilities';
  min-width: 8rem;
}
.confirm-button {
  composes: btn btn-primary btn-large from '../../style/components';
}
.cancel-button {
  composes: btn btn-tertiary btn-large from '../../style/components';
}
/*# sourceMappingURL=src/components/ConfirmationModal/ConfirmationModal.css.map */