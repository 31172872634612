.container-loading {
  composes: w-100 pa2 from '../../style/utilities';
}
.name-loading-base {
  composes: u-pulse-bg from '../../style/utilities';
  width: 80%;
  height: 2rem;
  margin: 1rem 0;
}
.desc-loading-base {
  width: 50%;
  height: 3rem;
  margin: 1rem 0;
}
.name-loading {
  composes: name-loading-base;
  composes: u-pulse-bg-white--opacity_300 from '../../style/utilities';
  composes: v-background--red from '../../style/visuals';
}
.desc-loading {
  composes: desc-loading-base;
  composes: v-background--black_30 from '../../style/visuals';
}
.name-loading-black {
  composes: name-loading-base;
  composes: u-pulse-bg--opacity_700 from '../../style/utilities';
  background-color: #000;
  opacity: 0.6;
}
.desc-loading-black {
  composes: desc-loading-base;
  background-color: #000;
  opacity: 0.6;
}
.name-loading-gray {
  composes: name-loading-base;
  composes: u-pulse-bg-white--opacity_300 from '../../style/utilities';
  background-color: #7d7d9a;
  opacity: 0.6;
}
.desc-loading-gray {
  composes: desc-loading-base;
  background-color: #7d7d9a;
  opacity: 0.6;
}
/*# sourceMappingURL=src/components/GenericLoader/GenericLoader.css.map */