.label {
  composes: w-100 tc f5 b mb2 from '../../style/utilities';
  color: #141414;
}
.input-loader {
  composes: br2 mt2 db w-100 from '../../style/utilities';
  max-height: 2.8rem;
}
.icon-base {
  composes: absolute from '../../style/utilities';
  fill: #141414;
  top: 55%;
  left: 0.8rem;
  transform: translateY(-50%);
  z-index: 2;
}
.icon-calendar {
  composes: icon-base;
}
.input {
  composes: tl mt2 from '../../style/utilities';
  font-size: 16px;
}
.input:disabled {
  pointer-events: none;
  opacity: 0.7;
}
.input-error {
  composes: input;
  border: 2px solid #e84046 !important;
}
.input-error:hover {
  border: 2px solid #800f13 !important;
}
.overlay-wrapper {
  z-index: 4;
}
.wrapper-date-base {
  composes: relative from '../../style/utilities';
}
.wrapper-date-base :global .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end) {
  background-color: #ccf8ff !important;
  color: #000;
}
.wrapper-date-base :global .DayPicker-Day {
  border-radius: 0 !important;
}
.wrapper-date-base :global .DayPicker-Day:hover {
  border-radius: 999px !important;
  background-color: #00bcda !important;
  color: #fff !important;
}
.wrapper-date-base :global .DayPicker-Day--start {
  background: #00bcda !important;
  color: #fff !important;
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}
.wrapper-date-base :global .DayPicker-Day--end {
  background: #00bcda !important;
  color: #fff !important;
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}
.wrapper-date {
  composes: wrapper-date-base;
  display: flex;
  align-items: center;
}
.wrapper-date :global .DayPickerInput {
  width: calc(100% - 2rem);
  display: block;
}
.wrapper-date-icon {
  composes: wrapper-date-base;
}
.wrapper-date-icon :global .DayPickerInput {
  width: calc(100% - 4rem);
  display: block;
}
/*# sourceMappingURL=src/components/EventFormDatePicker/EventFormDatePicker.css.map */