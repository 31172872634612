.row-basic {
  composes: flex flex-column w-100 center from '../../style/utilities';
  max-width: 77rem;
  z-index: 2;
}
.row {
  composes: row-basic;
  composes: flex-row-l justify-center items-start from '../../style/utilities';
}
.tags-modal {
  composes: pa4 from '../../style/utilities';
}
.tags-container {
  composes: pt2 pb2 from '../../style/utilities';
  max-height: 600px;
  overflow-y: auto;
}
.checkbox-wrapper {
  composes: flex items-center mb4 from '../../style/utilities';
}
.checkbox-wrapper label {
  margin-left: 8px;
}
.buttons-wrapper {
  composes: w-100 flex justify-end items-center from '../../style/utilities';
}
.cancel-btn {
  composes: btn btn-large btn-secondary from '../../style/components';
}
.save-btn {
  composes: btn btn-large btn-primary from '../../style/components';
  margin-left: 16px;
}
/*# sourceMappingURL=src/containers/CalendarEventsList/CalendarEventsList.css.map */