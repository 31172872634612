.container {
  composes: relative mt3 from '../../style/utilities';
}
.row {
  composes: flex flex-column flex-row-ns flex-wrap-ns from '../../style/utilities';
}
.row-info {
  composes: row;
  opacity: 0;
  visibility: hidden;
  height: 0;
}
.row-details {
  composes: row;
}
.col-base {
  composes: pa3 w-100 from '../../style/utilities';
}
.col {
  composes: col-base;
}
.col-half {
  composes: col-base;
  composes: w-100 w-50-ns from '../../style/utilities';
}
.col-two-thirds {
  composes: col-base;
  composes: w-100 w-two-thirds-ns from '../../style/utilities';
}
.col-third {
  composes: col-base;
  composes: w-100 w-third-ns from '../../style/utilities';
}
.col-fourth {
  composes: col-base;
  composes: w-50 from '../../style/utilities';
}
@media (min-width: 576px) {
  .col-fourth {
    width: 30%;
  }
}
.col-eight {
  composes: col-base;
  composes: w-50 from '../../style/utilities';
}
@media (min-width: 576px) {
  .col-eight {
    width: 13%;
  }
}
.col-city {
  composes: col-base;
  composes: w-100 from '../../style/utilities';
}
@media (min-width: 576px) {
  .col-city {
    width: 45%;
  }
}
.link-venue {
  composes: underline pa3 from '../../style/utilities';
  composes: c-button--reset from '../../style/components';
  color: #141414;
}
.link-venue:hover {
  opacity: 0.7;
}
.toggle-info {
  composes: flex flex-row justify-between w-100 pt2 pb3 ma2 b f4 tl from '../../style/utilities';
  composes: c-button--reset from '../../style/components';
  border-bottom: 1px solid #d5d5d5;
  color: #141414;
  width: calc(100% - 1rem);
}
.toggle-info:hover {
  text-decoration: underline;
}
.icon-plus {
  composes: b f4 from '../../style/utilities';
  fill: #141414;
  margin-right: calc(2rem + 3px);
  transform: rotate(0);
  transition: all 0.2s ease;
}
.title {
  composes: w-100 f4 b mb0 from '../../style/utilities';
  color: #141414;
  padding-bottom: 25px;
  display: block;
}
:global .leaflet-container {
  width: calc(100% - 1rem);
  height: 200px;
  margin: 0.5rem;
}
.label {
  composes: w-100 tl f4 b from '../../style/utilities';
  color: #141414;
  margin-bottom: 18px;
}
.input-loader {
  composes: br2 mt2 db w-100 from '../../style/utilities';
  max-height: 2.8rem;
}
.wrapper-input {
  composes: relative from '../../style/utilities';
}
.page-container {
  composes: flex flex-row br2 mt2 relative from '../../style/utilities';
  border: 2px solid #c6ced4 !important;
}
.page-container:hover,
.page-container:focus {
  border: 2px solid #141414 !important;
  cursor: pointer;
}
.page-selected,
.page {
  composes: w-100 pa3 tl f5 from '../../style/utilities';
  composes: c-button--reset from '../../style/components';
}
.page:hover {
  background: #00bcda;
  color: #fff !important;
}
.select-page {
  composes: w-100 pr4 b--none relative v-mid b from '../../style/utilities';
  appearance: none;
  line-height: normal;
  background: none;
  z-index: 2;
  padding: 0.8rem 2.2rem 0.8rem 0.8rem;
  color: #141414;
}
.select-page:hover {
  cursor: pointer;
}
.image {
  composes: dib pr2 v-mid from '../../style/utilities';
  max-height: 20px;
}
.arrow-icon {
  composes: dib absolute from '../../style/utilities';
  width: 10px;
  height: 5px;
  top: 50%;
  right: 1rem;
  margin-top: -2.5px;
  z-index: 1;
}
.dropdown-paneL {
  composes: db absolute pa3 br2 left-0 w-100 dn from '../../style/utilities';
  composes: v-background--white from '../../style/visuals';
  box-shadow: 0 0 6px 2px rgba(107,120,139,0.15);
  min-width: 10rem;
  max-width: 70rem;
  max-height: 15rem;
  overflow: hidden;
  overflow-y: auto;
  opacity: 0;
  visibility: hidden;
  top: 90%;
  z-index: 99;
}
.sub-title {
  color: #323232;
  padding-left: 0.5rem;
  margin-top: 30px;
  margin-bottom: 18px;
  padding: 0 !important;
  padding-left: 1rem !important;
}
.margin-bottom {
  margin-bottom: 30px;
}
.row-title {
  composes: flex w-100 justify-between tl from '../../style/utilities';
  margin-left: 1rem;
  padding-bottom: 0;
}
.row-sub-title {
  padding-left: 1rem;
  padding-top: 10px;
  padding-bottom: 5px;
}
.events-count-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 0.25rem;
  width: 20%;
}
@media (max-width: 576px) {
  .events-count-box {
    align-items: center;
    width: 100%;
  }
}
.events-count-box-number {
  margin-top: 28px;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: bold;
  color: #00bcda;
}
@media (max-width: 576px) {
  .events-count-box-number {
    margin-top: 1rem;
  }
}
/*# sourceMappingURL=src/components/UserCalendarForm/UserCalendarForm.css.map */