.box {
  composes: br3 w-100 h-100 from '../../style/utilities';
}
.image {
  composes: db h-100 w-100 pb4 center from '../../style/utilities';
  max-width: 42rem;
  z-index: 1;
}
.wrapper-img {
  composes: relative from '../../style/utilities';
}
.cloud {
  composes: dn db-l absolute from '../../style/utilities';
  height: auto;
  z-index: 2;
}
.link {
  composes: db center f5 w-100 tc b pb4 from '../../style/utilities';
  composes: v-color--black from '../../style/visuals';
}
@media screen and (min-width: 60em) {
  .link {
    color: #00bcda;
  }
}
/*# sourceMappingURL=src/pages/ErrorPage/ErrorPage.css.map */