.c-button--reset,
.c-button {
  cursor: pointer;
}
.c-button--reset {
  composes: pa0 ma0 bn from '../../style/utilities';
  background: none;
  box-shadow: none;
  appearance: none;
}
.c-button--reset:focus {
  outline: 0;
}
.c-button {
  composes: br2 ph4 pv2 from '../../style/utilities';
  text-rendering: auto;
  vertical-align: middle;
  transition: all ease 0.2s;
  border-color: #ccc;
}
.c-button:active {
  transform: translateY(0.07rem);
}
.c-button--red {
  composes: v-color--white from '../../style/visuals';
  border: 1px solid #00bcda;
  transition: all 0.2s ease;
  background-color: #00bcda;
}
.c-button--red:hover {
  background-color: #0090a7;
}
.c-button--gray_outline {
  composes: v-background--transparent from '../../style/visuals';
  border: 2px solid #7d7d9a;
  color: #7d7d9a;
  transition: all 0.2s ease;
}
.c-button--gray_outline:hover {
  background-color: #7d7d9a;
  color: #fff;
}
.c-button--gray_outline:hover svg {
  fill: #fff;
}
.c-button--gray_outline:hover svg path {
  fill: #fff;
}
.btn {
  color: #fff;
  appearance: none;
  border: none;
  background: none;
  cursor: pointer;
  text-transform: none;
  text-decoration: none;
  box-shadow: 4px 4px 4px rgba(12,56,145,0.1);
  border-radius: 40px;
}
.btn-xlarge,
.btn-large,
.btn-medium,
.btn-regular,
.btn-small,
.btn-xsmall {
  line-height: 140%;
  font-weight: 700;
}
.btn-xlarge {
  font-size: 18px;
  padding: 10px 40px;
}
.btn-large {
  font-size: 16px;
  padding: 10px 40px;
}
.btn-medium {
  font-size: 14px;
  padding: 10px 40px;
}
.btn-regular {
  font-size: 12px;
  padding: 10px 40px;
}
.btn-small {
  font-size: 10px;
  padding: 10px 40px;
}
.btn-xsmall {
  font-size: 8px;
  padding: 10px 40px;
}
.btn:disabled,
.btn.disabled {
  cursor: not-allowed;
  box-shadow: none;
  pointer-events: none;
  background-color: #eceff1;
  color: #b1bec7;
}
.btn-primary,
.btn[primary] {
  background-color: #00bcda;
}
.btn-primary:hover,
.btn[primary]:hover {
  background-color: #a6e9f2;
}
.btn-secondary,
.btn[secondary] {
  background-color: #0c3891;
}
.btn-secondary:hover,
.btn[secondary]:hover {
  background-color: #2859bb;
}
.btn-tertiary,
.btn[tertiary] {
  background-color: #fff;
  color: #0c3891;
}
.btn-tertiary:hover,
.btn[tertiary]:hover {
  background-color: #fff;
  color: #2859bb;
}
.btn-action-primary,
.btn-action-secondary,
.btn-action-tertiary {
  box-shadow: none;
  border-radius: 0;
  background-color: transparent;
  color: #0c3891;
}
.btn-action-primary:disabled,
.btn-action-secondary:disabled,
.btn-action-tertiary:disabled {
  color: #b1bec7;
  pointer-events: none;
}
.btn-action-primary:hover,
.btn-action-secondary:hover,
.btn-action-tertiary:hover {
  color: #2859bb;
}
.btn-action-primary:hover,
.btn-action-secondary:hover,
.btn-action-tertiary:hover,
.btn-action-primary:active,
.btn-action-secondary:active,
.btn-action-tertiary:active {
  text-decoration: underline;
}
.btn-action-secondary {
  color: #00bcda;
}
.btn-action-secondary:hover {
  color: #a6e9f2;
}
.button-primary {
  composes: btn btn-large btn-primary;
}
.button-secondary {
  composes: btn btn-large btn-secondary;
}
.button-secondary-large {
  composes: btn btn-large btn-secondary;
}
.button-tertiary-large {
  composes: btn btn-large btn-tertiary;
}
.c-modal {
  composes: flex-column items-center from '../../style/utilities';
}
.c-modal-overlay {
  composes: fixed bottom-0 left-0 right-0 top-0 from '../../style/utilities';
  composes: v-background--black_60 from '../../style/visuals';
  z-index: 102;
}
.c-toast-container {
  width: 100% !important;
}
.c-toast-container__full {
  left: 0 !important;
  margin-left: 0 !important;
  width: 100% !important;
  padding: 0 !important;
}
:global .c-toast-container__full.Toastify__toast-container--top-center {
  top: 0 !important;
}
:global .c-toast-container__full.Toastify__toast-container--bottom-center {
  bottom: 0 !important;
}
:global(.Toastify__toast--default):local(.c-toast__black) {
  transform: none !important;
  background: #3a3f49;
  opacity: 1 !important;
  color: #fff;
  margin: 0;
}
:global(.Toastify__toast--default):local(.c-toast__black) :global .Toastify__close-button--default {
  fill: #fff;
  color: #fff;
  opacity: 1;
}
/*# sourceMappingURL=src/style/components/index.css.map */