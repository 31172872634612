html,
body {
  background-color: #fff;
  font-family: 'Dm Sans', sans-serif;
  color: primary_dark;
  height: 100%;
  padding: 0;
  margin: 0;
  font-size: 15px;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (max-width: 576px) {
  html,
  body {
    font-size: 14px;
  }
}
body {
  min-height: 100vh;
}
:global .ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}
:global a {
  color: #0c3891;
  text-decoration: none;
}
.body-xlarge {
  font-size: 18px;
  line-height: 25px;
}
.body-large {
  font-size: 16px;
  line-height: 22px;
}
.body-medium {
  font-size: 14px;
  line-height: 20px;
}
.body-regular {
  font-size: 12px;
  line-height: 18px;
}
.body-small {
  font-size: 10px;
  line-height: 16px;
}
.body-xsmall {
  font-size: 8px;
  line-height: 14px;
}
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Dm Sans', sans-serif;
  font-weight: 500;
  font-style: normal;
  color: #1d1f20;
  line-height: 110%;
}
h1 {
  font-size: 48px;
  font-weight: 700;
}
h2 {
  font-size: 40px;
  padding-bottom: 25px;
  margin: 0;
}
@media (max-width: 576px) {
  h2 {
    font-size: 30px;
    line-height: 40px;
    padding-bottom: 20px;
  }
}
h3 {
  font-size: 32px;
  padding-bottom: 18px;
  margin: 0;
}
@media (max-width: 576px) {
  h3 {
    font-size: 20px;
    line-height: 28px;
    padding-bottom: 14px;
  }
}
h4 {
  font-size: 24px;
  padding-bottom: 11px;
  margin: 0;
}
@media (max-width: 576px) {
  h4 {
    font-size: 14px;
    line-height: 22px;
    padding-bottom: 0;
  }
}
h5 {
  font-size: 20px;
  padding-bottom: 25px;
  margin: 0;
}
@media (max-width: 576px) {
  h5 {
    font-size: 16px;
    line-height: 22px;
    padding-bottom: 0;
  }
}
h6 {
  font-size: 18px;
  padding-bottom: 25px;
  margin: 0;
}
@media (max-width: 576px) {
  h6 {
    font-size: 14px;
    line-height: 22px;
    padding-bottom: 0;
  }
}
.heading-1 {
  font-size: 48px;
  line-height: 52px;
}
.heading-2 {
  font-size: 40px;
  line-height: 44px;
}
.heading-3 {
  font-size: 32px;
  line-height: 35px;
}
.heading-4 {
  font-size: 24px;
  line-height: 26px;
}
.heading-5 {
  font-size: 20px;
  line-height: 22px;
}
.heading-6 {
  font-size: 18px;
  line-height: 20px;
}
input,
input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
textarea,
.input {
  appearance: none;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 4px 4px 4px rgba(12,56,145,0.1);
  font-family: 'Dm Sans', sans-serif;
  font-size: 16px !important;
  color: #2e2e2e;
  font-weight: 500;
  padding: 16px calc(1 * 16px);
  border: 1px solid #eceff1;
  width: 100%;
  line-height: 1 !important;
  margin-top: 0 !important;
}
input:focus,
input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="number"]:focus,
textarea:focus,
.input:focus {
  outline: 0;
}
input::placeholder,
input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="password"]::placeholder,
input[type="number"]::placeholder,
textarea::placeholder,
.input::placeholder {
  color: #c1c1c1;
}
input[type="password"],
input[type="password"]::placeholder {
  letter-spacing: 2px;
}
input:disabled {
  color: #c1c1c1 !important;
  background: #eceff7 !important;
  filter: drop-shadow(4px 4px 4px rgba(16,59,145,0.1));
}
label {
  font-size: 0.9rem;
  font-weight: 500;
}
input[type="checkbox"] {
  appearance: none;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  transform: translateY(-0.075em);
  border: none;
  display: grid;
  place-content: center;
  box-shadow: none;
  border: 1px solid #b1bec7;
}
input[type="checkbox"]::before {
  content: "";
  width: 14px;
  height: 14px;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #b1bec7;
}
input[type="checkbox"]:checked {
  background-color: #0c3891;
  border-color: #0c3891;
}
input[type="checkbox"]:checked::before {
  transform: scale(1);
}
input[type="checkbox"]:disabled {
  --form-control-color: #959495;
  color: #959495;
  cursor: not-allowed;
}
:global #root {
  position: absolute;
  height: 100%;
  width: 100%;
}
:global :focus {
  outline: 0;
}
:global .Toastify__close-button {
  align-self: center;
}
/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none /* IE and Edge */;
  scrollbar-width: none /* Firefox */;
}
/*# sourceMappingURL=src/style/main.css.map */