.cropper-wrapper {
  composes: ph3 from '../../style/utilities';
  height: 500px;
  max-width: 100%;
}
.row {
  composes: flex justify-between pa3 from '../../style/utilities';
}
.col-full {
  composes: fl w-100 from '../../style/utilities';
}
.col-half {
  composes: col-full;
  composes: w-50-ns from '../../style/utilities';
}
.col-half-tl {
  composes: col-half;
  composes: tl from '../../style/utilities';
}
.col-half-tr {
  composes: col-half;
  composes: tr from '../../style/utilities';
}
.button {
  composes: btn btn-secondary btn-large from '../../style/components';
}
.white-button {
  composes: btn btn-tertiary btn-large from '../../style/components';
}
/*# sourceMappingURL=src/components/CropperView/CropperView.css.map */