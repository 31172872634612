.label {
  composes: w-100 tc f5 b mb2 from '../../style/utilities';
  color: #141414;
}
.input-loader {
  composes: br2 mt2 db w-100 from '../../style/utilities';
  max-height: 2.8rem;
}
.wrapper-input {
  composes: relative flex items-center from '../../style/utilities';
}
.icon-base {
  composes: absolute from '../../style/utilities';
  fill: #141414;
  top: 50%;
  left: 0.8rem;
  transform: translateY(-50%);
  z-index: 2;
}
.icon-pencil {
  composes: icon-base;
}
.input {
  composes: tl mt2 from '../../style/utilities';
  font-size: 12px !important;
}
.input:disabled {
  pointer-events: none;
  opacity: 0.7;
  background: #f5f5f5;
}
.input-error {
  composes: input;
  border: 2px solid #e54653 !important;
}
.input-error:hover {
  border: 2px solid #80121b !important;
}
.sub-message-container {
  composes: f6 tl mt2 from '../../style/utilities';
  font-weight: normal;
}
.button-copy {
  composes: btn btn-large btn-tertiary from '../../style/components';
}
/*# sourceMappingURL=src/components/CopyToClipboardTextInput/CopyToClipboardTextInput.css.map */