.wrapper {
  composes: flex flex-column items-center justify-center from '../../style/utilities';
  color: #f1f8ff;
}
.row-third {
  composes: flex flex-column flex-row-l w-100 justify-between items-start ph2 pv4 pa4-ns from '../../style/utilities';
  max-width: 80rem;
}
.row-wrapper {
  composes: flex flex-row justify-center items-center w-100 from '../../style/utilities';
  background-color: #1d1f20;
  color: #f1f8ff;
}
.col {
  composes: h-100 f6 pv2 pv0-l flex justify-center from '../../style/utilities';
}
.col-left {
  composes: col;
  composes: w-100 w-30-l justify-start-l from '../../style/utilities';
}
.col-center {
  composes: col;
  composes: w-100 flex-wrap items-center w-60-l from '../../style/utilities';
}
.col-right {
  composes: col;
  composes: w-100 w-70-l justify-between-l from '../../style/utilities';
  margin-left: auto;
}
@media (min-width: 960px) {
  .col-right {
    width: auto;
  }
}
.address {
  composes: pa0 ma0 from '../../style/utilities';
}
.col-center .info:last-child {
  margin-right: 0;
}
.info {
  composes: list flex flex-column pa0 justify-start f6 w-100 w-auto-ns mr3-m mr5-l from '../../style/utilities';
}
.info > li {
  margin-bottom: 1rem;
}
.info > li:first-child {
  font-weight: bold;
}
.info > li:last-child::after {
  content: '';
}
@media screen and (max-width: 35em) {
  .info {
    margin-left: 1rem;
    flex-direction: column;
  }
  .info > li {
    margin-bottom: 0.5rem;
  }
  .info > li > button {
    text-align: left;
  }
  .info > li:after {
    content: '';
  }
}
.infoEl {
  composes: pr2 from '../../style/utilities';
}
.infoElTitle {
  font-size: 16px;
}
.infoElTitleApps {
  font-size: 16px;
}
@media (max-width: 576px) {
  .infoElTitleApps {
    margin-top: -16px;
  }
}
.name {
  composes: ttu from '../../style/utilities';
}
.link {
  composes: no-underline from '../../style/utilities';
  color: #f1f8ff;
}
.link:hover {
  opacity: 0.6;
}
.link-button {
  composes: link;
  cursor: pointer;
  appearance: none;
  border: none;
  background-color: transparent;
  padding-left: 0;
}
.social {
  composes: flex justify-center justify-end-l w-100 from '../../style/utilities';
}
.divider {
  composes: w-100 from '../../style/utilities';
  border: 0;
  border-top: 1px solid #d5d5d5;
}
.link-social {
  composes: dib v-mid mr2 from '../../style/utilities';
}
.link-social:hover {
  opacity: 0.6;
}
.logo {
  composes: mb3 w-100 from '../../style/utilities';
  max-width: 10rem;
}
.icon > path {
  fill: #707070;
}
/*# sourceMappingURL=src/components/Footer/Footer.css.map */