.promote-wrapper {
  composes: flex items-start justify-start w-100 from '../../style/utilities';
}
.number-container {
  composes: w-100 tl from '../../style/utilities';
}
.box-place {
  composes: ttc flex flex-wrap from '../../style/utilities';
}
.promoted-td {
  composes: flex justify-between items-center w-100 from '../../style/utilities';
}
.promo-days {
  composes: b from '../../style/utilities';
  font-size: 1rem;
}
.link {
  composes: c-button--reset from '../../style/components';
  composes: flex flex-row items-center no-underline pointer tl b from '../../style/utilities';
  white-space: normal;
  color: #7d7d9a;
  padding: 1rem 0.5rem;
}
.link:hover {
  background: #00bcda;
  color: #fff;
  border-radius: 4px;
}
.icon {
  composes: db from '../../style/utilities';
  width: 8px;
  height: 10px;
  fill: #7d7d9a;
  stroke: #7d7d9a;
}
.link-icon {
  composes: db mr2 from '../../style/utilities';
  fill: #7d7d9a;
  min-width: 16px;
}
.dropdown-container {
  composes: flex flex-column from '../../style/utilities';
}
.button-action-secondary {
  composes: btn btn-large btn-tertiary from '../../style/components';
}
.button-menu {
  cursor: pointer;
}
.promo-base {
  composes: tl from '../../style/utilities';
  white-space: pre-wrap;
  max-width: calc(100% - 1rem);
}
.promo-active {
  composes: promo-base;
  color: #00bcda;
}
.promo-finished {
  composes: promo-base;
  color: rgba(0,0,0,0);
}
.promo {
  composes: promo-base;
  color: #000;
}
.small-date-wrapper {
  padding: 0.3rem 0 0.2rem;
}
.list-promo {
  composes: flex flex-column from '../../style/utilities';
}
.item-promo {
  composes: flex justify-start from '../../style/utilities';
}
.text-link-blue {
  composes: db b underline pt2 from '../../style/utilities';
  cursor: pointer;
  color: #28f;
}
.tooltip-overlay-base :global .rc-tooltip-inner {
  color: #fff;
  box-shadow: 0 -4px 20px 5px rgba(0,0,0,0.11);
  background-color: #000;
  font-size: 0.9rem;
  font-weight: 700;
}
.tooltip-overlay {
  composes: tooltip-overlay-base;
}
.tooltip-overlay-no-arrow {
  composes: tooltip-overlay-base;
}
.tooltip-overlay-no-arrow :global .rc-tooltip-arrow {
  display: none;
  border-bottom-color: #000;
}
.checkbox {
  height: 20px;
  width: 20px;
}
.rejected {
  color: #e84046;
}
.rejected-cell {
  color: #b1bec7;
}
.popover {
  box-shadow: 4px 4px 4px rgba(12,56,145,0.1);
  border-radius: 4px;
  overflow: hidden;
  overflow-y: auto;
  background-color: #fff;
  padding: 0.5rem;
}
.popover-input-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}
.popover-input-wrapper label {
  margin-left: 4px;
}
:global .react-tiny-popover-container {
  position: absolute;
  left: 0;
  width: 100%;
  background: #fff;
  box-shadow: 4px 4px 4px rgba(12,56,145,0.1);
  border-radius: 5px;
  min-width: 14rem;
  max-width: 14rem;
  max-height: 17rem;
  overflow: hidden;
  overflow-y: auto;
  top: 90%;
  z-index: 99;
}
.box-tags {
  border-radius: 999px;
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0c3891;
  color: #fff;
  padding: 14px;
  font-size: 10px;
  cursor: pointer;
  margin-left: 8px;
}
.tags-tooltip-list {
  composes: list ma0 pa0 from '../../style/utilities';
  min-width: 10rem;
}
.tags-tooltip-list .tag-item-tooltip:last-child {
  margin-bottom: 0;
}
.channel-item-tooltip {
  composes: pa0 mb2 from '../../style/utilities';
  display: block;
}
.tooltip-overlay :global .rc-tooltip-arrow {
  display: none;
  border-bottom-color: #000;
}
.tooltip-overlay :global .rc-tooltip-inner {
  color: #fff;
  box-shadow: 0 -4px 20px 5px rgba(0,0,0,0.11);
  background-color: #000;
  font-size: 0.9rem;
  font-weight: 700;
}
.tags-cell {
  display: flex;
  align-items: center;
  width: 100%;
}
/*# sourceMappingURL=src/components/TableCalendarEventsColumns/TableCalendarEventsColumns.css.map */