.row {
  composes: flex flex-column w-100 center flex-row-l items-center from '../../style/utilities';
  margin: 0;
  padding: 0.5rem 0;
}
.row-between {
  composes: row;
  composes: justify-between from '../../style/utilities';
  width: 100%;
}
.row-start {
  composes: row;
  composes: justify-start from '../../style/utilities';
}
.row-end {
  composes: row;
  composes: justify-end from '../../style/utilities';
}
.col {
  composes: flex-l items-start-l w-100 w-50-l h-100 from '../../style/utilities';
}
.checkbox {
  height: 20px;
  width: 20px;
}
.checkbox-block {
  composes: flex flex-column from '../../style/utilities';
}
.checkbox-wrapper {
  composes: flex items-center from '../../style/utilities';
  margin-top: 5px;
  margin-bottom: 5px;
}
.checkbox-wrapper:last-child {
  margin-bottom: 20px;
}
.dropdown-wrapper {
  padding: 16px;
}
.dropdown-title {
  color: #1d1f20;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
  padding-bottom: 10px;
}
/*# sourceMappingURL=src/components/TableCalendarEventsFilter/TableCalendarEventsFilter.css.map */