.label {
  composes: w-100 tc f5 b mb2 from '../../style/utilities';
  color: #141414;
}
.input-loader {
  composes: br2 mt2 db w-100 from '../../style/utilities';
  max-height: 2.8rem;
}
.wrapper-input {
  composes: relative flex from '../../style/utilities';
}
.icon-base {
  composes: absolute from '../../style/utilities';
  fill: #141414;
  top: 50%;
  left: 0.8rem;
  transform: translateY(-50%);
  z-index: 2;
}
.icon-city {
  composes: icon-base;
}
.input {
  composes: tl mt2 from '../../style/utilities';
}
.input:disabled {
  pointer-events: none;
  background-color: #eceff7;
}
.input-error {
  composes: input;
  border: 2px solid #e54653 !important;
}
.input-error:hover {
  border: 2px solid #80121b !important;
}
.autocomplete-container {
  composes: absolute from '../../style/utilities';
  transform: translateX(-50%);
  top: 100%;
  width: 100%;
  left: 50%;
  opacity: 1;
  z-index: 3;
}
.autocomplete-item {
  composes: pa2 f5 tl from '../../style/utilities';
  background-color: #fff;
  border: 1px solid #dee6ec;
  color: #141414;
}
/*# sourceMappingURL=src/components/EventFormAddress/EventFormAddress.css.map */