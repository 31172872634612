.wrapper {
  display: block;
  position: absolute;
  z-index: 100;
  box-shadow: 0 3px 20px 1px rgba(0,0,0,0.1), 0 0 1px 1px rgba(0,0,0,0.03);
  padding: 0.5rem;
  border-radius: 8px;
  background: #fff;
  width: 87%;
}
.wrapper ul {
  list-style-type: none;
  margin: 0;
  padding: 0px 0 16px 0;
}
.wrapper ul li {
  display: flex;
  align-items: center;
  padding: 4px 0 4px 0;
}
.wrapper ul div {
  margin-left: 13px;
}
.wrapper .ellipsis {
  text-align: left;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/*# sourceMappingURL=src/components/Autocomplete/components/Results.css.map */