.content {
  composes: pt2 pt4-ns pb4 overflow-x-visible from '../../style/utilities';
}
.section-title-base {
  composes: f4 normal dib from '../../style/utilities';
  color: #000;
  padding-bottom: 1rem;
  margin-bottom: -2.5px;
}
.section-title-active {
  composes: section-title-base;
  composes: mr3 fw7 from '../../style/utilities';
  border-bottom: 5px solid #00bcda;
}
.section-title {
  composes: section-title-base;
  composes: no-underline from '../../style/utilities';
}
.section-title:hover {
  border-bottom: 5px solid #00bcda;
}
.hr {
  composes: bn ma0 mb3 from '../../style/utilities';
  height: 0.07rem;
  background-color: rgba(125,125,154,0.3);
}
.button-wrapper {
  composes: flex items-start justify-end w-100 from '../../style/utilities';
}
.promote-wrapper {
  composes: flex items-start justify-start w-100 from '../../style/utilities';
}
.button-base {
  composes: db tc w-100 fw7 from '../../style/utilities';
  composes: c-button from '../../style/components';
  max-width: 8rem;
  padding: 5px 1rem;
  text-decoration: none;
  font-size: 100%;
  line-height: 1.15;
}
.promoted-td {
  composes: flex justify-between items-center w-100 from '../../style/utilities';
}
.button-action {
  composes: button-base;
  composes: c-button--gray_outline from '../../style/components';
  composes: mh2 from '../../style/utilities';
  margin-right: 0;
  min-height: 2rem;
}
.button-action .icon {
  padding-top: 2px;
}
.button-action:hover .icon {
  fill: #fff;
  stroke: #fff;
}
.modal-container {
  composes: pa2 from '../../style/utilities';
  margin-bottom: 4.5rem;
}
.plans-info-wrapper {
  composes: pa2 from '../../style/utilities';
}
.event-title {
  composes: db f4 tc w-100 from '../../style/utilities';
  composes: v-color--blue from '../../style/visuals';
}
.event-avaible {
  composes: db tc f3 from '../../style/utilities';
  color: #7b8697;
  font-weight: normal;
}
.divider {
  border: 0;
  border-top: 1px solid #eaeff4;
  margin: 2rem 0;
}
.loader-container {
  composes: pa3 from '../../style/utilities';
}
.status {
  composes: db from '../../style/utilities';
}
.status-active {
  composes: status;
  composes: v-color--green from '../../style/visuals';
}
.status-active svg {
  fill: #008000;
  stroke: #008000;
}
.status-ended {
  composes: status;
  opacity: 0.5;
  color: #8b8b8b;
}
.status-ended svg {
  fill: #8b8b8b;
  stroke: #8b8b8b;
}
.status-waiting {
  composes: status;
  color: #333;
}
.status-waiting svg {
  fill: #333;
  stroke: #333;
}
.promo {
  composes: db fw7 from '../../style/utilities';
  color: #333;
}
.promo svg {
  fill: #333;
  stroke: #333;
}
.promo-active {
  composes: promo;
  color: #008000;
}
.promo-active svg {
  fill: #008000;
  stroke: #008000;
}
.promo-finished {
  composes: fw7 from '../../style/utilities';
  composes: promo;
  color: #999;
}
.promo-finished svg {
  fill: #999;
  stroke: #999;
}
.row-actions {
  composes: relarive pv2 ph3 flex items-center br2 br--bottom justify-end fw6 from '../../style/utilities';
  color: #7d7d9a;
  background-color: rgba(125,125,154,0.25);
  height: 2.25rem;
  margin-left: -0.25rem;
}
.row-actions span,
.row-actions a,
.row-actions .link {
  color: #7d7d9a;
  display: inline-block;
  margin: 0 0.5rem;
}
.row-actions span:hover,
.row-actions a:hover,
.row-actions .link:hover {
  color: #595972;
}
.link {
  composes: c-button--reset from '../../style/components';
  composes: flex flex-row items-center no-underline pointer ttc b from '../../style/utilities';
  color: #7d7d9a;
  padding: 1rem 0.5rem;
}
.link:hover {
  background: #eff2f4;
  border-radius: 4px;
}
.icon {
  composes: db from '../../style/utilities';
  width: 8px;
  height: 10px;
  fill: #7d7d9a;
  stroke: #7d7d9a;
}
.link-icon {
  composes: db mr2 from '../../style/utilities';
  fill: #7d7d9a;
}
.table-fetching-wrapper {
  composes: mt5 from '../../style/utilities';
}
.row-fetching-wrapper {
  composes: mb2 w-100 overflow-hidden br1 from '../../style/utilities';
  height: 5.5rem;
}
.number-container {
  composes: w-100 tc from '../../style/utilities';
}
.notification {
  composes: c-toast__black from '../../style/components';
}
.dropdown-container {
  composes: flex flex-column from '../../style/utilities';
}
/*# sourceMappingURL=src/containers/UserCalendarList/UserCalendarList.css.map */