.wrapper {
  composes: relative from '../../style/utilities';
}
.dropdown-panel {
  composes: dn absolute br2 left-0 w-100 dn from '../../style/utilities';
  composes: v-background--white from '../../style/visuals';
  box-shadow: 4px 4px 4px rgba(12,56,145,0.1);
  border-radius: 5px;
  min-width: 14rem;
  max-width: 14rem;
  max-height: 17rem;
  overflow: hidden;
  overflow-y: auto;
  opacity: 0;
  visibility: hidden;
  top: 90%;
  z-index: 99;
}
/*# sourceMappingURL=src/components/Dropdown/Dropdown.css.map */