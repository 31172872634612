.container-base {
  composes: cf w-100 from '../../style/utilities';
  margin: 0 auto;
}
@media (max-width: 576px) {
  .container-base {
    padding-right: 2rem;
    padding-left: 2rem;
  }
}
.container-big {
  composes: container-base;
  max-width: 80rem;
  min-height: 70vh;
}
.container-wide {
  composes: container-base;
  max-width: 1250px;
  min-height: 70vh;
}
.container {
  composes: container-base;
  max-width: 66rem;
}
.container-full {
  composes: cf w-100 from '../../style/utilities';
}
/*# sourceMappingURL=src/components/Container/Container.css.map */