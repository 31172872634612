.page {
  display: flex;
  flex-direction: column;
  margin: 32px auto;
  max-width: 1250px;
  min-height: 600px;
}
.box {
  width: 400px;
}
.date-row {
  margin-top: 12px;
  width: 400px;
  display: flex;
}
.end-date {
  margin-left: 6px;
}
.button {
  margin-top: 16px;
  width: 400px;
  composes: btn btn-primary btn-large from '../../style/components';
}
.pagination-button {
  composes: btn btn-primary btn-medium from '../../style/components';
  margin-right: 8px;
}
.events {
  margin-top: 32px;
}
.link {
  background: none;
  border: none;
  text-decoration: underline;
  padding: 0;
  cursor: pointer;
  margin-bottom: 20px;
  margin-right: 12px;
}
.popup {
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translateX(-100%);
  background: #fff;
  padding: 20px 30px;
  border: 1px solid #ccc;
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  z-index: 9999;
  border-radius: 8px;
  text-align: center;
}
.close {
  position: absolute;
  top: 8px;
  right: 12px;
  font-weight: bold;
  font-size: 16px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  line-height: 1;
}
/*# sourceMappingURL=src/pages/ReversePublishPage/ReversePublishPage.css.map */