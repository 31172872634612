.container {
  composes: relative br3 pv2 ph4 from '../../style/utilities';
}
.container-shadow {
  composes: container;
  box-shadow: 0 0 11px -3px rgba(0,0,0,0.2);
}
.button {
  composes: c-button from '../../style/components';
  composes: mv0-ns mb2 no-underline mw6-ns b from '../../style/utilities';
}
.button-green {
  composes: btn btn-tertiary btn-large from '../../style/components';
  composes: mw6-ns from '../../style/utilities';
}
.button-outline {
  composes: button-secondary from '../../style/components';
}
.content {
  composes: flex flex-column items-center justify-center pv3 tc from '../../style/utilities';
}
.content-event {
  composes: content;
  composes: mv0 pt3 pb5 tc from '../../style/utilities';
}
.title {
  composes: mv0 pt0 from '../../style/utilities';
  color: #000;
  font-weight: bold;
}
.title-small {
  composes: title;
  composes: pb2 f5 from '../../style/utilities';
}
.title-big {
  composes: title;
  composes: pb3 f3 from '../../style/utilities';
}
.text {
  composes: mv0 pt2 pb4 lh-copy f4 from '../../style/utilities';
  color: #000;
}
.text-small {
  composes: text;
  composes: f5 from '../../style/utilities';
}
.text-big {
  composes: text;
  composes: f4 from '../../style/utilities';
}
.icon {
  composes: flex self-center mb3 from '../../style/utilities';
}
.action-container {
  composes: flex flex-column flex-row-ns justify-center from '../../style/utilities';
}
.link {
  composes: b underline ph4 pv2 mb3 mb0-ns pointer from '../../style/utilities';
}
.link:active,
.link:focus,
.link:visited {
  color: #000;
}
.link:hover {
  color: #666;
}
/*# sourceMappingURL=src/components/BoxInfo/BoxInfo.css.map */