.container {
  composes: mt3 mb2 mb3-ns from '../../style/utilities';
}
.row {
  composes: flex flex-column flex-row-ns items-center ph0 from '../../style/utilities';
}
.row-center {
  composes: row;
  composes: justify-center from '../../style/utilities';
}
@media screen and (min-width: 30em) {
  .row-center > .col:last-child {
    margin-right: 0;
  }
}
.col {
  composes: w-100 w-auto-ns mb3 mb0-ns ml3 ml0-ns mr3 from '../../style/utilities';
}
.col-full {
  composes: w-100 from '../../style/utilities';
}
.hr {
  composes: bn ma0 from '../../style/utilities';
  height: 0.07rem;
  background-color: rgba(125,125,154,0.3);
}
.box {
  composes: flex flex-row mt3-ns from '../../style/utilities';
}
.icon {
  composes: db mr3 br3 from '../../style/utilities';
  max-height: 55px;
}
.info {
  composes: flex flex-column f5 mr4 from '../../style/utilities';
}
.info strong {
  padding-bottom: 0.7rem;
}
.button {
  composes: button-secondary from '../../style/components';
}
/*# sourceMappingURL=src/components/ImportCreateEventActions/ImportCreateEventActions.css.map */