.bg {
  composes: v-background--gray_200 from '../../style/visuals';
}
.title {
  composes: mb3 mt4 ttu from '../../style/utilities';
  font-weight: bold;
  font-size: 1.57rem;
}
.container {
  composes: pb4 ph3 from '../../style/utilities';
}
.section-title {
  composes: f3 fw7 normal mb4 mb1-ns from '../../style/utilities';
  composes: v-color--gray_700 from '../../style/visuals';
}
/*# sourceMappingURL=src/pages/ContentManagerPage/ContentManagerPage.css.map */