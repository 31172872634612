input,
input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
textarea,
.input {
  appearance: none;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 4px 4px 4px rgba(12,56,145,0.1);
  font-family: 'Dm Sans', sans-serif;
  font-size: 16px !important;
  color: #2e2e2e;
  font-weight: 500;
  padding: 16px calc(1 * 16px);
  border: 1px solid #eceff1;
  width: 100%;
  line-height: 1 !important;
  margin-top: 0 !important;
}
input:focus,
input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus,
input[type="number"]:focus,
textarea:focus,
.input:focus {
  outline: 0;
}
input::placeholder,
input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="password"]::placeholder,
input[type="number"]::placeholder,
textarea::placeholder,
.input::placeholder {
  color: #c1c1c1;
}
input[type="password"],
input[type="password"]::placeholder {
  letter-spacing: 2px;
}
input:disabled {
  color: #c1c1c1 !important;
  background: #eceff7 !important;
  filter: drop-shadow(4px 4px 4px rgba(16,59,145,0.1));
}
label {
  font-size: 0.9rem;
  font-weight: 500;
}
input[type="checkbox"] {
  appearance: none;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  transform: translateY(-0.075em);
  border: none;
  display: grid;
  place-content: center;
  box-shadow: none;
  border: 1px solid #b1bec7;
}
input[type="checkbox"]::before {
  content: "";
  width: 14px;
  height: 14px;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #b1bec7;
}
input[type="checkbox"]:checked {
  background-color: #0c3891;
  border-color: #0c3891;
}
input[type="checkbox"]:checked::before {
  transform: scale(1);
}
input[type="checkbox"]:disabled {
  --form-control-color: #959495;
  color: #959495;
  cursor: not-allowed;
}
.label {
  composes: w-100 tl f4 b mb2 from '../../style/utilities';
  color: #141414;
}
.input-loader {
  composes: br2 mt2 db w-100 from '../../style/utilities';
  max-height: 2.8rem;
}
.wrapper-input {
  composes: relative from '../../style/utilities';
}
.page-container {
  composes: flex flex-row br2 mt2 relative from '../../style/utilities';
  composes: input;
  height: 53px;
}
.page-container:hover,
.page-container:focus {
  cursor: pointer;
}
.page-selected,
.page {
  composes: w-100 tl f5 from '../../style/utilities';
  composes: c-button--reset from '../../style/components';
}
.page-selected {
  composes: flex items-center from '../../style/utilities';
}
.page {
  cursor: pointer;
  background: none;
  border: none;
  padding: 16px calc(1 * 16px);
}
.page:hover {
  background: #00bcda;
  color: #fff !important;
}
.select-page {
  composes: w-100 pr4 b--none relative v-mid b from '../../style/utilities';
  appearance: none;
  line-height: normal;
  background: none;
  z-index: 2;
  padding: 0.8rem 2.2rem 0.8rem 0.8rem;
  color: #141414;
}
.select-page:hover {
  cursor: pointer;
}
.image {
  composes: dib pr2 v-mid from '../../style/utilities';
  max-height: 20px;
}
.arrow-icon {
  composes: dib absolute from '../../style/utilities';
  right: 1rem;
  transition: all 0.2s ease;
  z-index: 1;
}
.dropdown-paneL {
  composes: db absolute pa2 br2 left-0 w-100 dn from '../../style/utilities';
  composes: v-background--white from '../../style/visuals';
  box-shadow: 0 0 6px 2px rgba(107,120,139,0.15);
  min-width: 10rem;
  max-width: 70rem;
  max-height: 13rem;
  overflow: hidden;
  overflow-y: auto;
  opacity: 0;
  visibility: hidden;
  top: 90%;
  z-index: 99;
}
.sub-message-container {
  composes: f6 tl from '../../style/utilities';
  font-weight: normal;
  margin-top: 10px;
}
/*# sourceMappingURL=src/components/EventFormDropdown/EventFormDropdown.css.map */