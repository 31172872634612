.content {
  composes: pv4 overflow-x-visible from '../../style/utilities';
}
.section-title {
  composes: f3 fw7 mb3 normal from '../../style/utilities';
  composes: v-color--gray_700 from '../../style/visuals';
}
.hr {
  composes: bn ma0 mb3 from '../../style/utilities';
  height: 0.07rem;
  background-color: rgba(125,125,154,0.3);
}
.row {
  composes: cf from '../../style/utilities';
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
@media screen and (min-width: 90rem) {
  .row {
    margin-left: -1rem;
    margin-right: -1rem;
  }
}
.col {
  composes: fl ph3-l ph2 from '../../style/utilities';
  width: 33.33%;
}
@media screen and (max-width: 90rem) {
  .col {
    width: 50%;
  }
}
@media screen and (max-width: 52rem) {
  .col {
    width: 100%;
  }
}
.button-connect {
  composes: db tc w-100 pa2 fw7 from '../../style/utilities';
  composes: c-button c-button--gray_outline from '../../style/components';
  max-width: 9rem;
  text-decoration: none;
  font-size: 100%;
  line-height: 1.15;
}
.section-desc {
  composes: mb4 from '../../style/utilities';
}
/*# sourceMappingURL=src/containers/UserIntegrationsList/UserIntegrationsList.css.map */