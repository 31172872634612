.wrapper-basic {
  composes: relative from '../../style/utilities';
  composes: v-color--white from '../../style/visuals';
  z-index: 99;
  background-color: #fff;
}
.wrapper {
  composes: wrapper-basic;
}
.wrapper .wrapper-user-action div div a {
  color: #b2b2b2;
}
.wrapper .wrapper-user-action div div a:hover {
  color: #777;
}
.icon {
  composes: mr2 from '../../style/utilities';
}
.wrapper-scroll {
  composes: wrapper-basic;
  composes: v-background--white from '../../style/visuals';
  border-color: rgba(107,120,139,0.15);
  z-index: 9;
}
.container {
  composes: tl flex flex-wrap items-center justify-between from '../../style/utilities';
  max-width: 1250px;
  margin: auto;
  height: 100%;
}
@media (max-width: 102rem) {
  .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
.wrapper-action {
  composes: flex from '../../style/utilities';
}
.logo {
  composes: db h-100 mw5 mw-none-ns center ma0-ns from '../../style/utilities';
  max-height: 5rem;
  margin-left: 1px;
  height: 25px;
}
.logo-events {
  composes: logo;
  height: 26px;
  width: 122px;
}
@media (max-width: 30em) {
  .menu {
    display: none;
  }
}
.anchor-link {
  composes: center ma0-ns from '../../style/utilities';
}
.nav-bar,
.wrapper-user-action {
  composes: flex flex-wrap items-center from '../../style/utilities';
}
.hamburger {
  composes: c-button--reset from '../../style/components';
}
@media screen and (min-width: 58em) {
  .hamburger {
    display: none;
  }
}
.link {
  composes: f6 no-underline ml4 pointer from '../../style/utilities';
}
.link-active {
  composes: link;
}
.link-logo {
  composes: link;
  composes: center mh0-ns from '../../style/utilities';
}
.link-logo-active {
  composes: link-logo;
}
.white-label-logo {
  max-height: 50px;
  margin-right: 10px;
  border-radius: 10px;
  border: 1px solid #323232;
}
.white-label-title {
  font-size: 16px;
  color: #323232;
  font-weight: bold;
}
.button {
  composes: button-secondary-large from '../../style/components';
  font-size: 16px;
  font-weight: bold;
}
@media (max-width: 30em) {
  .button {
    display: none !important;
  }
}
.edc-header {
  composes: pa2 ph0-l from '../../style/utilities';
  background: #eceff1;
  padding-left: 0;
  padding-right: 0;
}
.promote-header {
  composes: pa3 ph2 ph0-l from '../../style/utilities';
  background: #fff;
  padding-left: 0;
  padding-right: 0;
}
@media (max-width: 30rem) {
  .promote-header {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
.link-base {
  composes: flex flex-row items-center no-underline pointer ttc from '../../style/utilities';
  transition: all ease 0.2s;
  margin-left: 0.5rem;
}
.link-base-dropdown {
  composes: link-base;
  composes: ttc from '../../style/utilities';
  color: #b2b2b2;
  margin: 0;
}
.link-dropdown {
  composes: link-base-dropdown;
  padding: 1rem 0.5rem;
  color: #000 !important;
}
.link-dropdown:hover {
  color: #000 !important;
  background: #eff2f4;
}
.link-dropdown:last-child {
  padding-bottom: 0;
}
.app-link-wrapper {
  composes: flex items-center from '../../style/utilities';
}
.app-link-icon {
  composes: mr3 from '../../style/utilities';
  height: 45px;
  width: 45px;
}
.app-link-logo {
  height: 20px;
  width: auto;
}
.app-link-description {
  composes: flex flex-column justify-start items-start from '../../style/utilities';
  color: #74879a;
  font-size: 14px;
}
.dropdown-title {
  padding: 1rem 0.5rem;
  color: #c1c1c1;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: none;
  font-size: 14px;
}
.mobile-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #fff;
  z-index: 999;
  margin-top: 110px;
  padding: 1rem;
}
@media (max-width: 30rem) {
  .mobile-container {
    margin-top: 80px;
  }
}
@media (min-width: 29em) {
  .mobile-menu {
    display: none;
  }
}
@media (max-width: 30em) {
  .usernav {
    display: none;
  }
}
@media (min-width: 30em) {
  .mobile-usernav {
    display: none;
  }
}
/*# sourceMappingURL=src/containers/Header/Header.css.map */