.row-base {
  composes: flex flex-column ph4 from '../../style/utilities';
  composes: v-color--black from '../../style/visuals';
}
@media screen and (min-width: 60em) {
  .row-base {
    flex-direction: row;
    background: #fff;
    margin: 0 -1rem;
  }
}
.row {
  composes: row-base;
  background: transparent;
}
@media screen and (max-width: 60em) {
  .row {
    background: #fff;
    margin: 0 -1rem;
  }
}
.row-form-base {
  composes: flex flex-column flex-row-ns mb3 w-100 mb5 from '../../style/utilities';
}
.row-form-wrapper {
  composes: row-form-base;
}
@media screen and (max-width: 60em) {
  .row-form-wrapper {
    flex-wrap: wrap;
  }
}
.row-form {
  composes: row-form-base;
  composes: flex-wrap from '../../style/utilities';
}
.col-left {
  composes: w-100 pr0 flex flex-column flex-wrap from '../../style/utilities';
}
@media screen and (min-width: 60em) {
  .col-left {
    max-width: 51rem;
    padding-right: 4rem;
  }
}
.col-right {
  composes: w-100 flex flex-column flex-wrap from '../../style/utilities';
}
@media screen and (min-width: 60em) {
  .col-right {
    max-width: 22rem;
  }
}
.box-shadow {
  composes: dt br2 fixed relative-l bottom-0 w-100 from '../../style/utilities';
  composes: v-background--white from '../../style/visuals';
  box-shadow: 0 3px 20px 1px rgba(0,0,0,0.1), 0 0 1px 1px rgba(0,0,0,0.03);
  padding: 1.5rem;
  border-radius: 8px;
}
@media screen and (max-width: 60em) {
  .box-shadow {
    border-radius: 2px 2px 0 0;
    z-index: 9999;
  }
}
.col-upload {
  composes: br2 w-100 from '../../style/utilities';
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 110px;
  padding-left: 1rem;
  padding-right: 1rem;
}
.col-form {
  composes: w-100 from '../../style/utilities';
}
.notification {
  composes: c-toast__black from '../../style/components';
}
.col-label {
  composes: w-100 from '../../style/utilities';
  padding-left: 1rem;
  text-align: left;
  margin-bottom: 1rem;
}
.label {
  composes: w-100 tc b mb2 from '../../style/utilities';
  color: #141414;
  font-size: 18px;
}
.sub-title {
  color: #323232;
  padding-left: 1rem;
  margin-top: 12px;
  margin-bottom: 18px;
}
/*# sourceMappingURL=src/containers/UserCalendar/UserCalendar.css.map */