:global .ReactTable:local(.table),
:global .ReactTable:local(.table-expandable) {
  border: 0;
  font-size: 0.9rem;
}
:global .ReactTable:local(.table) :local(.rt-thead).-header,
:global .ReactTable:local(.table-expandable) :local(.rt-thead).-header {
  box-shadow: none;
  background-color: #0c3891;
  border-radius: 4px;
}
:global .ReactTable:local(.table) :local(.rt-thead).-header :local(.rt-td),
:global .ReactTable:local(.table-expandable) :local(.rt-thead).-header :local(.rt-td) {
  padding: 1rem 0.8rem;
  font-size: 0.9rem;
}
:global .ReactTable:local(.table) :local(.rt-th) > div,
:global .ReactTable:local(.table-expandable) :local(.rt-th) > div {
  white-space: nowrap;
}
:global .ReactTable:local(.table) :local(.rt-th),
:global .ReactTable:local(.table-expandable) :local(.rt-th),
:global .ReactTable:local(.table) :local(.rt-thead) :local(.rt-th),
:global .ReactTable:local(.table-expandable) :local(.rt-thead) :local(.rt-th) {
  font-weight: bold;
  color: #fff;
  font-size: 0.85rem;
  padding: 1rem 0.5rem 1rem 0.25rem;
  border: 0;
  text-transform: capitalize;
  overflow: visible;
}
:global .ReactTable:local(.table) :global :local(.rt-th).-sort-asc :local(.sorting-arrow),
:global .ReactTable:local(.table-expandable) :global :local(.rt-th).-sort-asc :local(.sorting-arrow) {
  display: block;
}
:global .ReactTable:local(.table) :global :local(.rt-th).-sort-desc :local(.sorting-arrow),
:global .ReactTable:local(.table-expandable) :global :local(.rt-th).-sort-desc :local(.sorting-arrow) {
  display: block;
  transform: rotate(180deg);
}
:global #root .ReactTable {
  width: 100%;
  margin-bottom: 16px;
}
:global #root .ReactTable .rt-table,
:global #root .ReactTable .rt-tbody {
  overflow: visible;
}
:global :local(.table) .rt-tbody {
  min-height: 40vh;
}
:global #root .ReactTable .-pagination {
  box-shadow: none;
  justify-content: flex-end;
  margin-bottom: 24px;
  margin-top: 24px;
  border: none;
}
:global #root .ReactTable .-pagination .-center {
  display: none;
}
:global #root .ReactTable .-pagination .-previous,
:global #root .ReactTable .-pagination .-next {
  -webkit-box-flex: 0;
  -ms-flex: 0;
  flex: 0;
}
:global #root .ReactTable .-pagination .-previous .-btn,
:global #root .ReactTable .-pagination .-next .-btn {
  color: #00bcda;
  background: transparent;
  width: 130%;
  margin-right: 24px;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}
:global #root .ReactTable .-pagination .-previous .-btn[disabled],
:global #root .ReactTable .-pagination .-next .-btn[disabled] {
  color: #b1bec7;
}
:global #root .ReactTable .rt-tbody .rt-td {
  border-right: 0 !important;
  text-overflow: ellipsis !important;
  overflow: hidden;
}
:global #root .ReactTable .rt-tbody .rt-td .calendar-url {
  max-width: 100%;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis !important;
}
:global .ReactTable .rt-tbody .rt-tr-group {
  border: 0 !important;
  overflow: visible;
  box-shadow: none;
  margin: 0;
  border-radius: 0;
  padding: 0;
}
:global #root .ReactTable .rt-thead .rt-th.-sort-desc,
:global #root .ReactTable .rt-thead .rt-td.-sort-desc,
:global #root .ReactTable .rt-thead .rt-th.-sort-asc,
:global #root .ReactTable .rt-thead .rt-td.-sort-asc {
  box-shadow: none;
}
:global :local(.table) :global .scrollbar-container.ps,
:global :local(.table-expandable) :global .scrollbar-container.ps {
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: -1rem;
  margin-right: -1rem;
  overflow: visible !important;
}
:global :local(.table) :global .scrollbar-container.ps--active-x .ps__rail-x,
:global :local(.table-expandable) :global .scrollbar-container.ps--active-x .ps__rail-x {
  opacity: 0;
  border-radius: 2rem;
  background-color: #ebebeb;
  height: 1rem;
  bottom: 1.15rem !important;
}
:global :local(.table) :global .scrollbar-container.ps--active-x .ps__rail-x :global .ps__thumb-x,
:global :local(.table-expandable) :global .scrollbar-container.ps--active-x .ps__rail-x :global .ps__thumb-x {
  background-color: #00bcda;
  height: 1.31rem;
  bottom: -2px;
  border-radius: 2rem;
  box-shadow: 0 0.125rem 0.375rem rgba(0,0,0,0.16);
  cursor: pointer;
}
:global :local(.table) :global .scrollbar-container.ps--active-x .ps__rail-x :global .ps__thumb-x:before,
:global :local(.table-expandable) :global .scrollbar-container.ps--active-x .ps__rail-x :global .ps__thumb-x:before,
:global :local(.table) :global .scrollbar-container.ps--active-x .ps__rail-x :global .ps__thumb-x:after,
:global :local(.table-expandable) :global .scrollbar-container.ps--active-x .ps__rail-x :global .ps__thumb-x:after {
  content: '';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border-bottom: 0.25rem solid transparent;
  border-top: 0.25rem solid transparent;
}
:global :local(.table) :global .scrollbar-container.ps--active-x .ps__rail-x :global .ps__thumb-x:before,
:global :local(.table-expandable) :global .scrollbar-container.ps--active-x .ps__rail-x :global .ps__thumb-x:before {
  border-right: 0.25rem solid #fff;
  left: 1rem;
}
:global :local(.table) :global .scrollbar-container.ps--active-x .ps__rail-x :global .ps__thumb-x:after,
:global :local(.table-expandable) :global .scrollbar-container.ps--active-x .ps__rail-x :global .ps__thumb-x:after {
  border-left: 0.25rem solid #fff;
  right: 1rem;
}
.rt-th {
  composes: flex flex-row items-center ttu from '../../style/utilities';
  overflow: visible;
}
.rt-th div {
  overflow: visible;
  white-space: break-spaces !important;
  font-size: 16px !important;
  text-align: left !important;
}
.rt-td {
  composes: flex flex-row items-center from '../../style/utilities';
}
.rt-thead {
  composes: pl1 ttu from '../../style/utilities';
}
:global #root .ReactTable .rt-thead .rt-tr {
  align-items: center !important;
}
.sorting-arrow {
  composes: ml2 dn from '../../style/utilities';
  width: 24px;
  height: 24px;
}
.table-row {
  composes: relative overflow-hidden br2 pl1 mv1 from '../../style/utilities';
  box-shadow: 0 0.125rem 0.375rem rgba(0,0,0,0.16);
  background: #fff;
}
.table-row-max-height {
  max-height: 65px;
}
.scroll-wrapper {
  composes: w-100 from '../../style/utilities';
}
.scroll-bar-wrapper {
  composes: mt3 w-90 m-auto from '../../style/utilities';
}
.button-reset {
  composes: c-button--reset from '../../style/components';
  background: transparent;
}
.row {
  composes: flex justify-around pa3 from '../../style/utilities';
}
.col-full {
  composes: fl w-100 from '../../style/utilities';
}
.col-half {
  composes: col-full;
  width: 45%;
}
.col-half div {
  font-size: 14px;
}
.col-half label {
  display: block;
  font-weight: bold;
  margin-bottom: 12px;
}
/*# sourceMappingURL=src/components/Table/Table.css.map */