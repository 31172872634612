.wrapper {
  color: #141414;
}
@media screen and (min-width: 30em) {
  .wrapper > .box:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
}
.box {
  z-index: 499;
}
.box-white {
  composes: box;
  composes: v-background--white from '../../style/visuals';
}
@media screen and (max-width: 60em) {
  .box-white {
    margin-left: -1rem;
    margin-right: -1rem;
    padding: 0 1rem;
    z-index: 99;
  }
}
.box-gray-base {
  composes: relative pa3 br2 from '../../style/utilities';
  background-color: #f5f7f8;
}
.box-gray {
  composes: box-gray-base;
  composes: mb3 flex-row flex-column justify-center items-center from '../../style/utilities';
}
@media screen and (max-width: 60em) {
  .box-gray {
    display: flex;
    background: #fff;
    padding: 0;
    margin-bottom: 0;
  }
}
.box-gray-full {
  composes: box-gray-base;
  display: none;
  margin-left: -1rem;
  margin-right: -1rem;
  margin-bottom: -1.5rem;
}
@media screen and (max-width: 60em) {
  .box-gray-full {
    display: block;
    min-height: 2rem;
    padding: 10px;
  }
}
.title {
  composes: f4 b flex items-center justify-between order-0 w-100 mr1 mr0-l from '../../style/utilities';
}
@media screen and (max-width: 60em) {
  .title {
    display: none;
    margin: 0.5rem 0.5rem 0.5rem 0;
  }
}
.percent {
  composes: relative absolute-l ml2 ml0-l f5 b w-100 order-2 tr from '../../style/utilities';
  color: #00bcda;
  max-width: 3rem;
}
@media screen and (min-width: 60em) {
  .percent {
    right: 1rem;
    top: 1rem;
  }
}
.meter {
  composes: relative pa0 w-100 order-1 from '../../style/utilities';
  height: 10px;
  background: #dbe1e8;
  border-radius: 20px;
  overflow: hidden;
}
.meter-value {
  composes: db relative from '../../style/utilities';
  height: 10px;
  background-color: #00bcda;
  border-radius: 20px;
  overflow: hidden;
}
.item {
  composes: flex flex-row items-center w-100 pv3 from '../../style/utilities';
  border-bottom: 1px solid #dee6ec;
}
.item-title {
  composes: b from '../../style/utilities';
}
.icon {
  composes: dib mr2 from '../../style/utilities';
  width: 20px;
}
.check {
  composes: dib from '../../style/utilities';
  margin-left: auto;
  transform: rotate(45deg);
  height: 12px;
  width: 8px;
  border-bottom: 4px solid #00bcda;
  border-right: 4px solid #00bcda;
}
.button-wrapper {
  composes: flex flex-row justify-between items-center from '../../style/utilities';
}
.button-wrapper > .button:fist-child {
  margin-right: 0.5rem;
}
.button-wrapper > .button:last-child {
  margin-left: 0.5rem;
}
@media screen and (max-width: 60em) {
  .button-wrapper {
    padding: 1rem 0;
    z-index: 103;
    height: 100%;
    background: #fff;
    position: relative;
    margin-bottom: 0;
  }
}
.button {
  composes: b w-100 from '../../style/utilities';
  padding: 0.8rem !important;
}
.button:hover {
  opacity: 0.8;
}
.save-button {
  composes: w-100 from '../../style/utilities';
  composes: btn btn-secondary btn-large from '../../style/components';
}
.link-button {
  composes: btn btn-action btn-action-secondary from '../../style/components';
}
.link-button-red {
  composes: underline from '../../style/utilities';
  composes: c-button--reset from '../../style/components';
  color: #f00;
}
.row {
  composes: flex flex-column flex-row-ns from '../../style/utilities';
}
.row-status {
  composes: flex flex-row justify-start items-start w-100 from '../../style/utilities';
  composes: c-button--reset from '../../style/components';
}
@media screen and (min-width: 60em) {
  .row-status {
    cursor: default;
  }
}
.col {
  composes: w-100 w-50-ns from '../../style/utilities';
}
.col-status {
  composes: flex flex-column justify-around-ns w-50-ns tl mr3 mr0-l from '../../style/utilities';
}
.col-mobile {
  composes: db b f3 from '../../style/utilities';
  width: 2rem;
  margin-left: auto;
}
@media screen and (min-width: 60em) {
  .col-mobile {
    display: none;
  }
}
.status-text {
  composes: b pr2 from '../../style/utilities';
}
.wrapper-arrow {
  composes: flex flex-row justify-center w-100 items-center from '../../style/utilities';
}
.arrow-icon {
  composes: db mr3 from '../../style/utilities';
  width: 20px;
  height: 10px;
  transition: all 0.2s ease;
}
.arrow-down {
  composes: arrow-icon;
}
.arrow-up {
  composes: arrow-icon;
  transform: rotate(180deg);
}
/*# sourceMappingURL=src/components/UserCalendarInfo/UserCalendarInfo.css.map */