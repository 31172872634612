.label {
  composes: w-100 tc f5 b mb2 from '../../style/utilities';
  color: #141414;
}
.input-loader {
  composes: br2 mt2 db w-100 from '../../style/utilities';
  max-height: 2.8rem;
}
.wrapper {
  composes: flex flex-column from '../../style/utilities';
}
.wrapper-input {
  composes: flex items-center mb3 from '../../style/utilities';
}
.tags-wrapper {
  composes: flex flex-wrap from '../../style/utilities';
  gap: 8px;
}
.tag {
  composes: flex items-center justify-around mb3 cf br2 from '../../style/utilities';
  background-color: #eceff1;
  color: #2e2e2e;
  box-shadow: 4px 4px 4px rgba(0,16,59,0.05);
  border-radius: 24px;
  padding: 8px 16px;
}
.tag:hover {
  background-color: #f4f8fb;
}
.tag-text {
  margin-right: 4px;
  font-size: 16px;
  font-weight: 400;
}
.tag-btn {
  composes: pointer from '../../style/utilities';
  height: 16px;
  width: 16px;
  border: none;
  padding: 0;
  margin: 0;
}
.icon-base {
  composes: absolute from '../../style/utilities';
  fill: #141414;
  top: 50%;
  left: 0.8rem;
  transform: translateY(-50%);
  z-index: 2;
}
.icon-pencil {
  composes: icon-base;
}
.input {
  composes: tl mt2 from '../../style/utilities';
}
.input:disabled {
  pointer-events: none;
  opacity: 0.7;
}
.input-error {
  composes: input;
  border: 2px solid #e54653 !important;
}
.input-error:hover {
  border: 2px solid #80121b !important;
}
.sub-message-container {
  composes: f6 tl from '../../style/utilities';
  font-weight: normal;
  margin-top: 10px;
}
.add-btn {
  composes: button-secondary from '../../style/components';
  max-height: 40px;
  min-height: 40px;
  min-width: 40px;
  max-width: 40px;
  padding: 0 !important;
  margin-left: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.remove-btn {
  composes: f6 fw6 tc br2 ph3 pv2 mb2 dib pointer from '../../style/utilities';
}
.autocomplete {
  position: inherit !important;
}
.autocomplete:first-child {
  width: 100%;
  height: unset;
  position: inherit !important;
  border: none;
}
.autocomplete :global .wrapper {
  position: inherit !important;
  border: none !important;
}
.autocomplete .wrapper:focus,
.autocomplete .wrapper:focus-within {
  box-shadow: none !important;
}
.autocomplete input {
  -webkit-appearance: none !important;
  appearance: none !important;
  background-color: #fff !important;
  border-radius: 5px !important;
  box-shadow: 4px 4px 4px rgba(12,56,145,0.1) !important;
  font-family: "Dm Sans", sans-serif !important;
  font-size: 16px !important;
  color: #2e2e2e !important;
  font-weight: 500 !important;
  padding: 16px !important;
  border: 1px solid #eceff1 !important;
  width: 100% !important;
  line-height: 1 !important;
  margin-top: 0 !important;
}
.autocomplete svg {
  display: none;
}
/*# sourceMappingURL=src/components/EventFormTags/EventFormTags.css.map */