.promote-wrapper {
  composes: flex items-start justify-start w-100 from '../../style/utilities';
}
.number-container {
  composes: w-100 tl from '../../style/utilities';
}
.box-place {
  composes: ttc flex flex-wrap from '../../style/utilities';
}
.promoted-td {
  composes: flex justify-between items-center w-100 from '../../style/utilities';
}
.promo-days {
  composes: b from '../../style/utilities';
  font-size: 1rem;
}
.link {
  composes: c-button--reset from '../../style/components';
  composes: flex flex-row items-center no-underline pointer tl b from '../../style/utilities';
  white-space: normal;
  color: #7d7d9a;
  padding: 1rem 0.5rem;
}
.link:hover {
  background: #eff2f4;
  border-radius: 4px;
}
.icon {
  composes: db from '../../style/utilities';
  width: 8px;
  height: 10px;
  fill: #7d7d9a;
  stroke: #7d7d9a;
}
.link-icon {
  composes: db mr2 from '../../style/utilities';
  fill: #7d7d9a;
  min-width: 16px;
}
.dropdown-container {
  composes: flex flex-column from '../../style/utilities';
}
.button-action-secondary {
  composes: btn btn-large btn-tertiary from '../../style/components';
}
.button-menu {
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  margin-left: 1rem;
}
.promo-base {
  composes: tl from '../../style/utilities';
  white-space: pre-wrap;
  max-width: calc(100% - 1rem);
}
.promo-active {
  composes: promo-base;
  color: #00bcda;
}
.promo-finished {
  composes: promo-base;
  color: rgba(0,0,0,0);
}
.promo {
  composes: promo-base;
  color: #000;
}
.small-date-wrapper {
  padding: 0.3rem 0 0.2rem;
}
.list-promo {
  composes: flex flex-column from '../../style/utilities';
}
.item-promo {
  composes: flex justify-start from '../../style/utilities';
}
.text-link-blue {
  composes: db b underline pt2 from '../../style/utilities';
  cursor: pointer;
  color: #28f;
}
.tooltip-overlay-base :global .rc-tooltip-inner {
  color: #fff;
  box-shadow: 0 -4px 20px 5px rgba(0,0,0,0.11);
  background-color: #000;
  font-size: 0.9rem;
  font-weight: 700;
}
.tooltip-overlay {
  composes: tooltip-overlay-base;
}
.tooltip-overlay-no-arrow {
  composes: tooltip-overlay-base;
}
.tooltip-overlay-no-arrow :global .rc-tooltip-arrow {
  display: none;
  border-bottom-color: #000;
}
/*# sourceMappingURL=src/components/TableUserCalendarColumns/TableUserCalendarColumns.css.map */