:global #PlacesAutocomplete__root {
  width: 100%;
}
.form-container {
  composes: flex flex-column flex-row-ns pv4 from '../../style/utilities';
}
.form-container > .col:last-child {
  padding-left: 3rem;
}
@media screen and (max-width: 30em) {
  .form-container > .col:last-child {
    padding-left: 0;
  }
}
.row {
  composes: flex flex-column flex-row-ns w-100 mv2 from '../../style/utilities';
}
.row-fix {
  composes: flex flex-row w-100 from '../../style/utilities';
}
.col,
.col-half {
  composes: flex flex-column w-100 w-50-ns mb4 mb0-ns from '../../style/utilities';
}
.col-full {
  composes: flex flex-column w-100 mb4 mb0-ns from '../../style/utilities';
}
.inner-col-base {
  composes: flex items-center pa1 from '../../style/utilities';
}
.inner-col-base-top {
  composes: inner-col-base;
  composes: mt3 from '../../style/utilities';
}
.inner-col-left {
  composes: inner-col-base;
  composes: justify-end-ns items-start w-100 w-25-ns from '../../style/utilities';
}
.inner-col-left-label {
  composes: inner-col-base;
  composes: w-80 mt2 from '../../style/utilities';
}
.inner-col-left-mini {
  composes: inner-col-base;
  composes: justify-end-ns items-start w-100 w-20-ns from '../../style/utilities';
}
.inner-col-right {
  composes: inner-col-base;
  composes: justify-start-ns flex-column w-100 items-start from '../../style/utilities';
}
.inner-col-right > label {
  text-align: left;
}
.inner-col-right-label {
  composes: inner-col-base;
  composes: justify-center w-20 mt2 from '../../style/utilities';
}
.inner-col-right-big {
  composes: inner-col-base;
  composes: justify-start-ns flex-column w-100 items-start from '../../style/utilities';
}
.input {
  composes: w-100 from '../../style/utilities';
}
.selectLang {
  composes: w-100 pv2 pr4 b--none relative v-mid from '../../style/utilities';
  color: #606a79;
  appearance: none;
  line-height: normal;
  background: none;
  z-index: 2;
}
.selectLang:hover {
  cursor: pointer;
}
.label {
  composes: mt3 mt0-ns w-100 from '../../style/utilities';
  cursor: pointer;
  color: #7b7b7b;
}
.label-notification {
  compoes: label;
  composes: flex from '../../style/utilities';
}
.content {
  composes: flex flex-column from '../../style/utilities';
}
.action-container {
  composes: flex items-end justify-end w-100 mb3 from '../../style/utilities';
}
.button-save {
  composes: button-primary from '../../style/components';
  max-width: 10rem;
}
.switch {
  display: flex !important;
}
.email-error {
  composes: pa2 f6 from '../../style/utilities';
  composes: v-color--red from '../../style/visuals';
}
.input-error {
  border: 2px solid #e54653 !important;
}
.input-error:hover {
  border: 2px solid #80121b !important;
}
.social-text {
  composes: ttc from '../../style/utilities';
}
.wrapper-input {
  composes: relative w-100 from '../../style/utilities';
}
.autocomplete-container {
  composes: absolute from '../../style/utilities';
  transform: translateX(-50%);
  width: 100%;
  left: 50%;
  opacity: 1;
  z-index: 3;
}
.autocomplete-item {
  composes: pa2 f5 tl from '../../style/utilities';
  background-color: #fff;
  border: 1px solid #dee6ec;
  color: #141414;
}
.page-container {
  composes: flex flex-row br2 mt2 relative from '../../style/utilities';
  appearance: none;
  height: 45px;
  background-color: #fff;
  border-radius: 7px;
  box-shadow: 0px 2px 10px #cfd8dc;
  font-family: 'Dm Sans', sans-serif;
  font-size: 16px;
  color: #323232;
  font-weight: 400;
  padding: 0.75rem;
  border: none !important;
}
.page-container:hover,
.page-container:focus {
  cursor: pointer;
}
.page-selected,
.page {
  composes: w-100 tl f5 from '../../style/utilities';
  composes: c-button--reset from '../../style/components';
}
.page {
  composes: pa2 from '../../style/utilities';
}
.page:hover {
  background: #f3f3f3;
}
.select-page {
  composes: w-100 pr4 b--none relative v-mid b from '../../style/utilities';
  appearance: none;
  line-height: normal;
  background: none;
  z-index: 2;
  padding: 0.8rem 2.2rem 0.8rem 0.8rem;
  color: #141414;
}
.select-page:hover {
  cursor: pointer;
}
.image {
  composes: dib pr2 v-mid from '../../style/utilities';
  max-height: 20px;
}
.arrow-icon {
  composes: dib absolute from '../../style/utilities';
  right: 1rem;
  margin-top: -2.5px;
  z-index: 1;
}
.dropdown-paneL {
  composes: db absolute pa2 br2 left-0 w-100 dn from '../../style/utilities';
  composes: v-background--white from '../../style/visuals';
  box-shadow: 0 0 6px 2px rgba(107,120,139,0.15);
  min-width: 10rem;
  max-width: 70rem;
  max-height: 13rem;
  overflow: hidden;
  overflow-y: auto;
  opacity: 0;
  visibility: hidden;
  top: 90%;
  z-index: 99;
}
/*# sourceMappingURL=src/containers/ProfileForm/ProfileForm.css.map */