.container {
  composes: mv3 relative br3 from '../../style/utilities';
  z-index: 4;
}
.container-shadow {
  composes: container;
  box-shadow: 0 0 5px 5px rgba(0,0,0,0.1);
}
.box-content-base {
  composes: br3 from '../../style/utilities';
  composes: v-color--black from '../../style/visuals';
  background-color: #fff;
  z-index: 4;
}
.box-content-margin {
  composes: box-content-base;
  composes: mb3 from '../../style/utilities';
}
.box-content {
  composes: box-content-base;
  composes: mb0 from '../../style/utilities';
}
/*# sourceMappingURL=src/components/Box/Box.css.map */