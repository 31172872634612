.container {
  composes: flex flex-row items-center from '../../style/utilities';
  font-size: 1rem;
}
.container-mobile {
  composes: flex flex-column from '../../style/utilities';
  font-size: 1rem;
  min-height: 100vh;
}
.link-base {
  composes: flex flex-row items-center no-underline pointer ttc from '../../style/utilities';
  transition: all ease 0.2s;
  margin-left: 0.5rem;
}
.link-button,
.link-button-home {
  composes: link-base;
  composes: b from '../../style/utilities';
  composes: c-button--reset from '../../style/components';
  font-size: 1rem;
}
.link-button {
  color: #000 !important;
}
.link-button-mobile {
  composes: link-base;
  composes: ml2 w-100 from '../../style/utilities';
  composes: c-button--reset from '../../style/components';
  color: #000;
  min-height: 2rem;
  padding: 0;
}
.link-button-mobile > .icon {
  margin-right: 0.5rem;
}
.link-base-dropdown {
  composes: link-base;
  composes: ttc from '../../style/utilities';
  color: #b2b2b2;
}
.link,
.link-home {
  composes: link-base;
  composes: b from '../../style/utilities';
}
.link > .icon {
  margin-left: 0.5rem;
}
.link-home svg {
  fill: #fff;
}
.link-home:hover {
  color: #f3f3f3;
}
.link-mobile {
  composes: link-base;
  composes: ml2 flex items-center from '../../style/utilities';
  color: #000;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 18px;
  margin-left: 1rem;
}
.link-dropdown {
  composes: link-base-dropdown;
  padding: 1rem 0.5rem;
  color: #000 !important;
  margin: 0;
}
.link-dropdown:hover {
  color: #000 !important;
  background: #eff2f4;
}
.link-dropdown:last-child {
  padding-bottom: 0;
}
.link-dropdown-active {
  composes: link-dropdown;
  composes: mb2 from '../../style/utilities';
  color: #000 !important;
  background: #eff2f4;
}
.link-active {
  composes: link;
  font-weight: 700;
}
.link-mobile-active {
  composes: link-mobile;
  font-weight: 700;
}
.profile-pic {
  composes: mw2 br-100 ml2 w-100 h-100 db from '../../style/utilities';
  height: 25px;
  width: 25px;
}
.button-logout-base {
  composes: flex flex-row items-center w-100 ttc from '../../style/utilities';
  composes: c-button c-button--reset from '../../style/components';
  transition: all ease 0.2s;
  padding: 0.5rem;
}
button.button-logout,
button.button-logout-home {
  color: #000 !important;
  padding: 1rem 0.5rem;
}
button.button-logout .dropdown-icon,
button.button-logout-home .dropdown-icon,
button.button-logout .dropdown-icon g,
button.button-logout-home .dropdown-icon g,
button.button-logout .dropdown-icon path,
button.button-logout-home .dropdown-icon path {
  fill: #000 !important;
  stroke: #000 !important;
}
button.button-logout:hover,
button.button-logout-home:hover {
  color: #000;
  background: #eff2f4;
}
.button-logout,
.button-logout-home,
.button-logout-mobile {
  composes: button-logout-base;
}
.button-logout-mobile {
  composes: ml2 from '../../style/utilities';
  padding-left: 0;
}
.button-logout-mobile > .icon {
  margin-right: 0.5rem;
}
.menu-wrapper {
  composes: flex flex-row items-center pv2 ml3 from '../../style/utilities';
  cursor: pointer;
}
.menu-wrapper:hover {
  opacity: 0.6;
}
.menu-wrapper > svg {
  fill: #b2b2b2;
  cursor: pointer;
}
.action {
  composes: relative from '../../style/utilities';
}
.button-menu {
  composes: flex c-button--reset from '../../style/components';
}
.button-menu:active {
  transform: translateY(0.07rem);
}
.dropdown-icon {
  composes: mr2 from '../../style/utilities';
  fill: #000;
  min-width: 15px;
}
.divider {
  composes: mv2 from '../../style/utilities';
  border: 0;
  border-top: 1px solid #e5e5e5;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.img-fallback {
  composes: ml2 db from '../../style/utilities';
  min-width: 25px;
}
.img-fallback circle {
  stroke: transparent !important;
}
.icon {
  composes: dib from '../../style/utilities';
}
.icon-nav {
  composes: mr2 from '../../style/utilities';
}
.name {
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  font-weight: bold;
  border: none;
  padding-bottom: 0;
  margin-bottom: 1rem;
}
.dropdown-title {
  padding: 1rem 0.5rem;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: bold;
  text-transform: capitalize;
  border-bottom: none;
  color: #000;
}
.button {
  composes: button-secondary from '../../style/components';
  padding-bottom: 15px;
  margin-left: 1rem;
}
/*# sourceMappingURL=src/components/UserNav/UserNav.css.map */