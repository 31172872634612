.toast-container {
  composes: c-toast-container from '../../style/components';
}
.toast-container-full {
  composes: c-toast-container__full from '../../style/components';
}
.toast-container-full:global(.Toastify__toast-container--top-center) {
  top: 0;
}
.toast-container-full:global(.Toastify__toast-container--bottom-center) {
  bottom: 0;
}
.button-primary {
  composes: button-primary from '../../style/components';
  max-width: 250px;
  margin: auto;
}
.rebranding-banner {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.spacer {
  padding-top: 100px;
}
@media (max-width: 576px) {
  .spacer {
    padding-top: 82px;
  }
}
/*# sourceMappingURL=src/pages/WrapperPage/WrapperPage.css.map */