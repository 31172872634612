.label {
  composes: w-100 tc f4 b mb2 from '../../style/utilities';
  color: #141414;
  margin-bottom: 18px;
}
.input-loader {
  composes: br2 mt2 db w-100 from '../../style/utilities';
  max-height: 2.8rem;
}
.wrapper-input {
  composes: relative flex from '../../style/utilities';
}
.error {
  composes: tl f6 mt2 from '../../style/utilities';
  color: #00bcda;
}
.title {
  composes: flex justify-between tl from '../../style/utilities';
  margin-bottom: 0.5rem;
}
.small-title {
  composes: flex justify-between tl f5 from '../../style/utilities';
}
@media screen and (max-width: 410px) {
  .mobile-switch-label {
    display: flex;
    flex-direction: column;
  }
}
.switch-wrapper {
  composes: flex justify-start items-center from '../../style/utilities';
}
.switch-div-wrapper {
  display: flex;
  align-items: center;
  max-height: 16px;
}
@media screen and (max-width: 410px) {
  .switch-div-wrapper {
    margin-top: 1rem;
  }
}
.switch-label {
  font-size: 16px;
  font-weight: 400;
  margin-left: 1rem;
}
.sub-title {
  font-weight: normal;
  font-size: 14px;
  margin-bottom: 25px;
  display: block;
  text-align: left;
  margin-top: 5px;
}
.address-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.address-title img {
  height: 16px;
}
/*# sourceMappingURL=src/components/EventFormItemWrapper/EventFormItemWrapper.css.map */