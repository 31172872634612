.button-toggle {
  display: flex;
}
.button-toggle button:first-child {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
.button-toggle button:last-child {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}
.button-toggle-image {
  margin-right: 5px;
}
.button-toggle-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  color: #000;
  padding: 12px 40px;
  font-weight: bold;
  cursor: pointer;
}
.active {
  border: none;
  color: #fff;
  background-color: #00bcda;
}
/*# sourceMappingURL=src/components/ButtonToggle/ButtonToggle.css.map */