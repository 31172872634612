.container {
  composes: flex flex-row items-center justify-between from '../../style/utilities';
}
.container-mobile {
  composes: flex flex-row mt2 from '../../style/utilities';
}
.login {
  margin-left: 1rem;
  padding-left: 1rem;
  border-left: 1px solid #000;
  color: #000;
  cursor: pointer;
}
.divider {
  composes: bw0 from '../../style/utilities';
  border-top: 1px solid #f0f1f4;
  margin: 0.5rem -1rem;
}
/*# sourceMappingURL=src/components/LoginSignupButtons/LoginSignupButtons.css.map */