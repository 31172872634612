.container-base {
  composes: relative w-100 from '../../style/utilities';
  overflow: hidden;
}
.container-red {
  composes: container-base;
  composes: v-color--white v-background--red from '../../style/visuals';
  padding-bottom: 0;
}
.container-white {
  composes: container-base;
  composes: v-color--black from '../../style/visuals';
  background: transparent;
}
.content-base {
  composes: relative flex flex-column center from '../../style/utilities';
  z-index: 2;
}
.content {
  composes: content-base;
  composes: tc tl-ns pv5-l from '../../style/utilities';
  max-width: 50rem;
}
.content-full {
  composes: content-base;
  composes: tc from '../../style/utilities';
}
.title {
  composes: b f3 pv2 ma0 from '../../style/utilities';
}
@media screen and (min-width: 60em) {
  .title {
    font-size: 2rem;
  }
}
.title-medium {
  composes: b ma0 from '../../style/utilities';
  font-size: 1.8rem;
}
.description {
  composes: f5 f4-ns from '../../style/utilities';
}
.wrapped-curved {
  composes: absolute right-0 left-0 w-100 dn db-l from '../../style/utilities';
  margin-bottom: -0.3rem;
  overflow: hidden;
  z-index: 1;
}
.curved-shape {
  composes: db from '../../style/utilities';
}
.row-basic {
  composes: flex flex-column w-100 center from '../../style/utilities';
  max-width: 77rem;
  z-index: 2;
}
.row {
  composes: row-basic;
  composes: flex-row-l justify-center items-start from '../../style/utilities';
}
.row-full {
  composes: row-basic;
  composes: justify-start from '../../style/utilities';
}
@media screen and (min-width: 60em) {
  .row-full {
    margin-bottom: 4rem;
  }
}
.col {
  composes: flex-l items-start-l w-100 w-50-l h-100 from '../../style/utilities';
}
.col-left {
  composes: flex items-center items-start w-100 w-50-l h-100 from '../../style/utilities';
}
@media screen and (min-width: 60em) {
  .col-left {
    padding: 6rem 0;
  }
}
.col-right {
  composes: col;
  composes: dn db-l from '../../style/utilities';
  padding-top: 6rem;
}
.col-full {
  composes: w-100 from '../../style/utilities';
  z-index: 2;
}
.spacer {
  composes: v-background--white from '../../style/visuals';
  margin-top: -1px;
}
/*# sourceMappingURL=src/components/BoxTitleDesc/BoxTitleDesc.css.map */