.wrapper {
  composes: relative h-100 from '../../style/utilities';
  display: flex;
  flex-direction: row;
  overflow: hidden;
  background: #fff;
  position: relative;
  min-width: 110px;
  margin-right: 20px;
}
.container {
  composes: flex flex-column justify-center items-center z-0 h-100 from '../../style/utilities';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.container:hover {
  cursor: pointer;
}
.container clipPath {
  display: flex;
  min-height: 100%;
}
.content-base {
  composes: flex flex-column justify-center ba b--dashed pa3 br2 tc w-100 h-100 from '../../style/utilities';
  border-width: 2px;
}
.content {
  composes: content-base;
  color: #b1bec7;
  border-color: #b1bec7;
}
.content-red {
  composes: content-base;
  color: #fd737f;
  border-color: #fd737f;
}
.icon-base {
  composes: db center from '../../style/utilities';
}
.icon {
  composes: icon-base;
  fill: #b1bec7;
}
.icon-red {
  composes: icon-base;
  fill: #fd737f;
}
.reset-icon {
  composes: absolute top-0 right-0 pa2 br-100 ma2 z-1 b--white-30 bg-white-30 from '../../style/utilities';
  cursor: pointer;
  transition: transform ease 0.2s;
  box-shadow: 0 2px 3px 0 rgba(0,0,0,0.16);
  border-width: 2px;
  border-style: inherit;
}
.reset-icon:active {
  transform: translateY(0.07rem);
}
.image {
  composes: w-100 from '../../style/utilities';
  object-fit: cover;
  min-height: 100%;
}
.loading-image {
  composes: db from '../../style/utilities';
  min-height: 100%;
  min-width: 100%;
}
.loading-image > rect {
  min-height: 100%;
}
@media screen and (max-width: 30em) {
  .loading-image {
    max-height: 10rem;
  }
}
.upload-text {
  composes: b ttu from '../../style/utilities';
}
.column {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  background-color: #f5f7f8;
  border-radius: 10px;
  width: 100%;
}
.column .sub-column {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-content: center;
  background-color: #f5f7f8;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}
.column span {
  text-align: center;
  font-size: 14px;
  color: rgba(0,0,0,0.35);
}
.upload-button {
  composes: btn btn-large btn-tertiary from '../../style/components';
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 auto;
}
.upload-button svg {
  height: 20px;
  margin-right: 10px;
  margin-top: -5px;
}
/*# sourceMappingURL=src/components/EventImageUpload/EventImageUpload.css.map */