.container {
  composes: flex flex-column items-center w-100 from '../../style/utilities';
  color: #141414;
}
.container .cat-tag:last-child {
  margin-bottom: 0;
}
.cat-tag,
.cat-tag-active {
  composes: flex flex-column relative pa3 mb3 cf br2 w-100 from '../../style/utilities';
  background-color: #fff;
  box-shadow: 4px 4px 4px rgba(0,16,59,0.05);
  border-radius: 8px;
}
.cat-tag:hover {
  background-color: #f4f8fb;
}
.cat-button {
  composes: tl w-100 from '../../style/utilities';
  background: transparent;
  border: none;
  cursor: pointer;
}
.cat-button-active {
  composes: cat-button;
}
.cat-col {
  composes: flex flex-row items-center w-100 from '../../style/utilities';
}
.cat-title {
  margin: 0;
  padding: 0;
  padding-right: 1rem;
  font-size: 16px;
  font-weight: 500;
}
.cat-subtitle {
  font-size: 14px;
  font-weight: 400;
}
.wrapper {
  composes: flex flex-row justify-between items-center from '../../style/utilities';
}
.tags-container {
  composes: flex flex-wrap from '../../style/utilities';
  overflow: hidden;
  height: 0;
  opacity: 0;
}
.arrows-wrapper {
  composes: flex from '../../style/utilities';
  background-color: transparent;
  cursor: pointer;
  border: 0;
}
.arrows-wrapper:focus {
  outline: 0;
}
.arrows-wrapper:active {
  transform: translateY(0.07rem);
}
.arrow-icon {
  composes: dib from '../../style/utilities';
  z-index: 9;
  transition: all 0.2s ease;
}
.tag-base {
  composes: fl mb2 mr2 from '../../style/utilities';
  cursor: pointer;
  text-transform: capitalize;
  padding: 0.2rem 1rem;
  border: 1px solid #fff;
  font-size: 12px;
  border-radius: 100px;
}
.tag-base:focus {
  outline: 0;
}
.tag-base:active {
  transform: translateY(0.07rem);
}
.tag {
  background-color: #fff;
  border-color: #74879a;
  color: #74879a;
  font-weight: bold;
}
.tag:hover {
  border-color: #313b44;
  color: #313b44 !important;
}
.tagActive {
  font-weight: bold;
  border: 1px solid rgba(255,255,255,0);
  color: #fff;
  background-color: #00bcda;
}
.tagActive:hover {
  opacity: 0.8;
}
.tagActive:hover,
.tagActive--active:hover {
  opacity: 0.9;
}
.tag-only {
  border: 1px solid #ced2db;
  background-color: #ced2db;
  padding: 0.4rem 1rem;
}
.tag-only-active {
  border: 1px solid #00bcda;
  background-color: #00bcda;
}
.box-shadow {
  height: 1px;
  width: calc(100% + 60px);
  margin-left: -30px;
  margin-right: -30px;
}
.box-shadow:before {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  content: '';
  display: block;
  position: absolute;
}
.input-loader {
  composes: br2 mt3 db w-100 from '../../style/utilities';
  max-height: 3.5rem;
}
.error {
  composes: b tl f6 mt2 mb4 w-100 from '../../style/utilities';
  color: #00bcda;
}
/*# sourceMappingURL=src/components/TagListSelection/TagListSelection.css.map */