.container {
  composes: relative flex justify-between w-100 mb3 from '../../style/utilities';
  composes: v-background--white from '../../style/visuals';
  height: 8.5rem;
  padding-left: 8rem;
  box-shadow: 0 0.125rem 0.375rem rgba(0,0,0,0.16);
}
@media screen and (max-width: 60rem) {
  .container {
    height: 10rem;
    flex-direction: column !important;
    justify-content: space-around !important;
  }
}
.icon {
  composes: absolute h-100 top-0 left-0 from '../../style/utilities';
  composes: v-background--gray_700 from '../../style/visuals';
  width: 8rem;
}
.icon-img {
  composes: w-100 h-100 from '../../style/utilities';
  object-fit: cover;
  object-position: center;
}
.content {
  composes: ma3 overflow-hidden from '../../style/utilities';
}
@media screen and (max-width: 60rem) {
  .content {
    margin-bottom: 1rem;
  }
}
.title {
  composes: f4 mb2 mt1 fw7 from '../../style/utilities';
}
.description {
  composes: ma0 f5 from '../../style/utilities';
}
@media screen and (max-width: 22rem) {
  .description {
    display: none;
  }
}
.buttons-wrapper {
  composes: mh3 mv3 flex items-center from '../../style/utilities';
}
@media screen and (max-width: 60rem) {
  .buttons-wrapper {
    margin-top: 0;
  }
}
.button-connect {
  composes: db tc w-100 pa2 fw7 from '../../style/utilities';
  composes: c-button c-button--gray_outline from '../../style/components';
  max-width: 9rem;
  text-decoration: none;
  font-size: 100%;
  line-height: 1.15;
}
.button-modal-disconnect {
  composes: db tc fw7 ph0 pv2 from '../../style/utilities';
  composes: c-button--reset from '../../style/components';
  color: #7f899b;
  max-width: 9rem;
  text-decoration: none;
  font-size: 100%;
  line-height: 1.15;
}
.button-modal-disconnect:hover {
  color: #656f82;
}
.modal-content {
  composes: pa4 tc from '../../style/utilities';
}
.modal-message {
  composes: f4 from '../../style/utilities';
}
.modal-buttons {
  composes: flex items-center justify-center mt4 from '../../style/utilities';
}
.modal-buttons button {
  margin: 0 0.5rem;
}
.status-badge {
  composes: dib br-pill ml2 v-mid from '../../style/utilities';
  background-color: #dee6ec;
  width: 12px;
  height: 12px;
}
.status-badge-active {
  composes: status-badge;
  background-color: #008000;
  animation: 1.5s linear infinite;
  animation-name: status-pulse;
}
@-moz-keyframes status-pulse {
  from {
    box-shadow: 0 0 0 0px rgba(0,128,0,0.35);
  }
  to {
    box-shadow: 0 0 0 10px rgba(0,128,0,0);
  }
}
@-webkit-keyframes status-pulse {
  from {
    box-shadow: 0 0 0 0px rgba(0,128,0,0.35);
  }
  to {
    box-shadow: 0 0 0 10px rgba(0,128,0,0);
  }
}
@-o-keyframes status-pulse {
  from {
    box-shadow: 0 0 0 0px rgba(0,128,0,0.35);
  }
  to {
    box-shadow: 0 0 0 10px rgba(0,128,0,0);
  }
}
@keyframes status-pulse {
  from {
    box-shadow: 0 0 0 0px rgba(0,128,0,0.35);
  }
  to {
    box-shadow: 0 0 0 10px rgba(0,128,0,0);
  }
}
/*# sourceMappingURL=src/components/BoxIntegration/BoxIntegration.css.map */