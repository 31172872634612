.modal {
  composes: c-modal from '../../style/components';
}
.modal-overlay {
  composes: c-modal-overlay from '../../style/components';
}
.modal-wrapper-basic {
  composes: absolute cf w-100 h-100 h-auto-ns from '../../style/utilities';
  composes: v-background--white from '../../style/visuals';
  transition: all 0.2s ease;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  opacity: 0;
  border-radius: 2rem;
  max-width: 740px;
}
@media (min-width: 481px) and (max-width: 767px) and (orientation: landscape) {
  .modal-wrapper-basic {
    max-width: 100% !important;
  }
}
@media (max-width: 480px) {
  .modal-wrapper-basic {
    max-width: 90%;
    height: auto;
  }
}
.modal-wrapper {
  composes: modal-wrapper-basic;
}
.modal-wrapper-compact {
  composes: modal-wrapper-basic;
  max-height: 30rem;
}
.modal-header {
  composes: flex flex-row items-center tc br2 br--top pa3 w-100 from '../../style/utilities';
  composes: v-background--white v-color--black from '../../style/visuals';
  border-bottom: 1px solid #f0f1f4;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
}
.modal-title {
  composes: f4 ma0 pr3 ph0-ns pr2 pr0-ns w-100 from '../../style/utilities';
  composes: v-color--black from '../../style/visuals';
  font-weight: 500;
}
@media (min-width: 481px) and (max-width: 767px) and (orientation: landscape) {
  .modal-body {
    max-height: 87vh !important;
    overflow: hidden !important;
  }
}
.close-icon {
  cursor: pointer;
  margin-right: 0.5rem;
}
/*# sourceMappingURL=src/components/ModalWrapper/ModalWrapper.css.map */