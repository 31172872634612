.title {
  composes: mb3 mt4 ttu from '../../style/utilities';
  font-weight: bold;
  font-size: 1.57rem;
}
.container {
  composes: pb4 ph2 from '../../style/utilities';
}
.section-title {
  margin-top: 60px;
  margin-bottom: 40px;
}
.row {
  composes: flex flex-column flex-row-l center tc w-100 items-center from '../../style/utilities';
  max-width: 1200px;
}
.row-center {
  composes: row;
  justify-content: center;
}
.col-base {
  composes: pa2 w-100 from '../../style/utilities';
}
.col {
  composes: col-base;
}
.col-half {
  composes: col-base;
  composes: w-100 w-50-ns from '../../style/utilities';
}
.col-half-left {
  composes: col-half;
  text-align: left;
}
.toggle {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.toggle span:first-child {
  margin-right: 10px;
}
.toggle span:not(:first-child) {
  margin-left: 10px;
}
.block-row {
  composes: row;
  display: block;
}
.box-cta {
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  box-shadow: 0px 3px 11px rgba(0,0,0,0.16);
  max-width: 300px;
  padding: 20px 30px;
  text-transform: none;
  text-decoration: none;
  color: inherit;
}
.box-cta:first-child {
  margin-right: 20px;
}
@media (max-width: 576px) {
  .box-cta:first-child {
    margin-right: 0;
    margin-bottom: 20px;
  }
}
.box-cta div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
}
.box-cta div h4 {
  margin-bottom: 8px;
}
.divider {
  composes: w-100 from '../../style/utilities';
  border: 0;
  border-top: 1px solid #d5d5d5;
  margin-bottom: 60px;
}
/*# sourceMappingURL=src/containers/CalendarCreationSuccess/CalendarCreationSuccess.css.map */