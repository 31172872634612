.wrapper {
  composes: w-100 overflow-hidden from '../../style/utilities';
}
.image-box {
  composes: dib from '../../style/utilities';
  min-width: 70px;
  height: 70px;
  overflow: visible;
  margin-right: 1rem;
  vertical-align: top;
}
.image {
  min-width: 70px;
  width: 70px;
  min-height: 70px;
  border-radius: 5px;
  object-fit: cover;
  max-height: 100%;
}
.data-box {
  composes: dib truncate overflow-hidden w-100 tl from '../../style/utilities';
  vertical-align: top;
}
.box-name {
  composes: db f5 mb1 tl from '../../style/utilities';
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 2.5rem;
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
}
.box-city-base {
  composes: dib br2 pv1 ph2 f6 mr2 mv1 from '../../style/utilities';
  background-color: #f4f8fb;
}
.box-city {
  composes: box-city-base;
  font-weight: normal;
}
.box-city-red {
  composes: box-city-base;
  composes: v-color--red from '../../style/visuals';
  cursor: pointer;
}
.address {
  composes: db truncate overflow-hidden w-100 tl from '../../style/utilities';
  padding: 5px 0;
}
.label-box {
  composes: db mt1 from '../../style/utilities';
}
.link-event {
  composes: dib pl3 flex items-center no-underline w-100 from '../../style/utilities';
  composes: c-button--reset from '../../style/components';
  border: 0;
  color: #333840;
  margin: 0;
  padding: 0;
  white-space: none !important;
}
.link-event:hover {
  color: #00bcda;
}
/*# sourceMappingURL=src/components/TableCellCalendar/TableCellCalendar.css.map */