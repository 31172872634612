.modal-content {
  composes: flex flex-column ph5 pb4 from '../../style/utilities';
}
.modal-body {
  composes: flex flex-column from '../../style/utilities';
}
.evensi-transition {
  composes: flex flex-row items-center justify-start from '../../style/utilities';
  margin-bottom: 2rem;
}
.button-primary {
  composes: button-primary from '../../style/components';
  margin: auto;
}
.modal-background {
  height: 250px;
  border-radius: 2rem;
  overflow: hidden;
}
@media (max-width: 480px) {
  .modal-background {
    height: 150px;
  }
}
.modal-background .circle {
  height: 1030px;
  width: 1030px;
  position: relative;
  bottom: 840px;
  right: 144px;
  background-color: #00bcda;
  border-radius: 1030px;
}
@media (max-width: 475px) {
  .modal-background .circle {
    bottom: 900px;
    right: 345px;
  }
}
/*# sourceMappingURL=src/components/RebrandingModal/RebrandingModal.css.map */