.link-base {
  composes: flex flex-row items-center no-underline pointer ttc from '../../style/utilities';
  transition: all ease 0.2s;
}
.app-link {
  composes: link-base;
  composes: ttc from '../../style/utilities';
  padding: 1rem 0.5rem;
  color: #000 !important;
}
.app-link:hover {
  color: #000 !important;
  background: #eff2f4;
}
.app-link:last-child {
  padding-bottom: 0.5rem;
}
@media (max-width: 30em) {
  .app-link {
    padding: 1rem 1rem;
  }
}
.app-link-wrapper {
  composes: flex items-center from '../../style/utilities';
}
.app-link-icon {
  composes: mr3 from '../../style/utilities';
  height: 45px;
  width: 45px;
}
.app-link-logo {
  height: 20px;
  width: auto;
}
.app-link-description {
  composes: flex flex-column justify-start items-start from '../../style/utilities';
  color: #74879a;
  font-size: 14px;
}
.dropdown-title {
  padding: 1rem 0.5rem;
  padding-bottom: 0;
  color: #c1c1c1;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: none;
  font-size: 14px;
}
@media (max-width: 30em) {
  .dropdown-title {
    display: none;
  }
}
/*# sourceMappingURL=src/components/AppSelector/AppSelector.css.map */